@import './variables.scss';

.download-loader {
    margin-top: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h6 {
        font-size: 25px;
        text-align: center;
    }

    p {
        text-align: center;
    }

    img {
        width: 100px;
    }
}