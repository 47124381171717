@import './variables.scss';


// Download Plan
.download-plan--page {
    width: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-content: center;
    margin: 0 auto;

    @media (max-width: $sm-max) {
        width: 90%;
    }

    .download-button {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    p.top-title {
        font: var(--unnamed-font-style-normal) normal 600 20px/38px var(--unnamed-font-family-poppins);
        letter-spacing: var(--unnamed-character-spacing-0);
        text-align: center;
        font: normal normal 600 20px/38px Poppins;
        letter-spacing: 0px;
        color: #742F4E;
        opacity: 1;
    }

    p.message-text {
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 16px/var(--unnamed-line-spacing-28) var(--unnamed-font-family-poppins);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(---1a1a12);
        text-align: center;
        font: normal normal normal 16px/28px Poppins;
        letter-spacing: 0px;
        color: #1A1A12;
        opacity: 1;
    }

    ul {
        li {
            font-size: 18px;
            // font-weight: $h3-weight;
            font-weight: 600;
            margin-bottom: 5px;

            @media (max-width: $sm-max) {
                font-size: 16px;
            }

            span {
                font-weight: $h4-weight;
            }
        }
    }

    .next-btn {
        margin-bottom: 10px;
        padding: 10px;
        font-size: 14px;
        height: 45px;
        width: 190px;

        @media (max-width: $sm-max) {
            font-size: 12px;
        }
    }

    .download-icon {
        img {
            height: 26px;
            width: 26px;

            @media (max-width: $sm-max) {
                max-width: 80%;
                height: 20px;
                width: 20px;
                margin-bottom: 15px;
            }
        }
    }

    .download-plan--text {
        text-transform: none;

        @media (min-width: $sm-max) {
            br {
                display: none;
            }
        }
    }
}

.download-form {
    .download-field {
        margin-bottom: 10px;
        display: block !important;
    }

    .small-field {
        padding-right: 10px !important;
        margin-right: 5px;
    }

    .small-field-container {
        width: 100%;
        display: flex;
    }

    .download-label {
        text-align: left;
        float: left;
        display: block !important;
        width: 100%;
    }
}


.download-plan-list {

    margin-top: -120px;
    // display: flex;
    // align-items: center;
    // justify-content: center;

    @media (max-width: $sm-max) {
        margin-top: -80px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Poppins' !important;
    }

    div,
    span,
    li,
    button,
    div.form-control,
    input.form-control,
    select.form-select {
        font-family: 'Poppins';
    }

    p {
        font-family: 'Poppins' !important;
        font-size: $content-font-size;
        line-height: $content-line-height;
    }

    li {
        font-family: 'Poppins' !important;
        line-height: $content-line-height;
    }

    .title--text {
        @media (max-width: $sm-max) {
            display: none;
        }
    }

    .download-btn {
        @media (max-width: $sm-max) {
            button {
                font-size: 12px;
            }

            img {
                margin-bottom: 10px;
                width: 25px;
            }

        }
    }
}