$faded-offset: 80px !default;
$faded-blur-radius: 80px !default;
$faded-spread-radius: 1px !default;

$faded-narrow-offset: 20px !default;
$faded-narrow-blur-radius: 20px !default;
$faded-narrow-spread-radius: 10px !default;

$faded-wide-offset: 80px !default;
$faded-wide-blur-radius: 80px !default;
$faded-wide-spread-radius: 28px !default;

@mixin faded($offset, $blur-radius, $spread-radius) {
    &.faded-left:after {
        box-shadow: inset $offset 0 $blur-radius (
            -$spread-radius
        );
}

&.faded-right:after {
    box-shadow: inset (-$offset) 0 $blur-radius (
        -$spread-radius
    );
}

&.faded-top:after {
    box-shadow: inset 0 $offset $blur-radius (
        -$spread-radius
    );
}

&.faded-bottom:after {
    box-shadow: inset 0 (-$offset) $blur-radius (
        -$spread-radius
    );
}

&.faded-left.faded-right:after {
    box-shadow: inset $offset 0 $blur-radius (
        -$spread-radius),
        inset (-$offset) 0 $blur-radius (
        -$spread-radius
    );
}

&.faded-left.faded-top:after {
    box-shadow: inset $offset (
        $offset) $blur-radius (
        -$spread-radius
    );
}

&.faded-right.faded-bottom:after {
    box-shadow: inset (-$offset) (-$offset) $blur-radius (
        -$spread-radius
    );
}

&.faded-top.faded-right:after {
    box-shadow: inset (-$offset) $offset $blur-radius (
        -$spread-radius
    );
}

&.faded-top.faded-bottom:after {
    box-shadow: inset 0 $offset $blur-radius (
        -$spread-radius),
        inset 0 (-$offset) $blur-radius (
        -$spread-radius
    );
}

&.faded-left.faded-bottom:after {
    box-shadow: inset $offset (
        -$offset) $blur-radius (
        -$spread-radius
    );
}

&.faded-top.faded-right.faded-bottom:after {
    box-shadow: inset (-$offset/2) 0 $blur-radius $spread-radius;
}

&.faded-left.faded-right.faded-bottom:after {
    box-shadow: inset 0 (-$offset/2) $blur-radius $spread-radius;
}

&.faded-left.faded-top.faded-bottom:after {
    box-shadow: inset ($offset/2) 0 $blur-radius $spread-radius;
}

&.faded-left.faded-top.faded-right:after {
    box-shadow: inset 0 ($offset/2) $blur-radius $spread-radius;
}

&.faded-all:after,
&.faded-left.faded-top.faded-right.faded-bottom:after {
    box-shadow: inset 0 0 $blur-radius $spread-radius;
}
}


.faded {
    position: relative;
    display: inline-block;
    color: white;
    overflow: hidden;
    top: 0px;
    right: 0;

    &:after {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: -10px;
        right: 0;
        width: 110%;
        height: 104%;
        border-collapse: separate;
    }

    @include faded($faded-offset, $faded-blur-radius, $faded-spread-radius);

    &.faded-narrow {
        @include faded($faded-narrow-offset, $faded-narrow-blur-radius, $faded-narrow-spread-radius);
    }

    &.faded-wide {
        @include faded($faded-wide-offset, $faded-wide-blur-radius, $faded-wide-spread-radius);
    }
}

// Scale images down if they're too large for available space
img {
    width: 100%;
}

.program-head-link {
    h4 {
        font-size: 1.5rem;
    }

    .arrow-forward-icon-container {
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: -20px;


        svg {
            // margin-top: 8px;
            height: 50% !important;
            // width: 100% !important;
            float: right;
        }
    }
}

.program-head-link-steps {
    h4 {
        font-size: 1.5rem;
    }

    .arrow-forward-icon-container {
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: -10px;
        height: 40px;


        svg {
            // margin-top: 8px;
            height: 60% !important;
            // width: 100% !important;
            float: right;
        }
    }
}


.programs-list {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    h4 {
        font-size: 1.5rem;
    }

    h5 {
        font-size: 1.2rem !important;
        margin-left: -9px !important;
    }

    @media (max-width: $sm-max) {
        width: 110%;
        margin: unset;
    }

    .title {
        font-size: 18px;
        font-family: $poppins;
        max-width: 669px;

        @media (max-width: $sm-max) {
            padding-right: 30px;
        }
    }

    .subtitle {
        font-size: 18px;
        font-family: $poppins-medium;
    }

    .image-card {
        width: 243px;
        height: 253px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        margin-top: 10px !important;

        img {
            // height: 100%;
            width: auto;
            // margin-top: 55px;
            // margin-right: 35px;
            scale: 159%;
        }
    }

    .program-title {
        margin-top: 8px;
        font-size: 1.2rem;
        font-family: $poppins-medium;
    }
}

.program-splash {

    .fixed {
        position: fixed;
    }

    .content {
        margin-bottom: 87px;
    }

    .program-button {
        position: fixed;
        background-color: #9FCD2B;
        color: #fff;
        width: 100%;
        height: 77px;
        bottom: 0;

        text-align: center;

        cursor: pointer;

        p {
            margin-top: 25px;
            font-family: $poppins-medium;
            font-size: 19px;
        }
    }

    .arrow-forward-icon-container {
        display: flex;

        svg {
            height: 80px !important;
            width: 80px !important;
            float: left;
        }
    }

    .program-button-desktop {
        background-color: #9FCD2B;
        color: #fff;
        width: 100%;
        height: 77px;
        text-align: center;
        border-radius: 10px;

        cursor: pointer;

        h4 {
            margin-top: 30px;
            margin-left: 50px;
            font-family: $poppins;
            font-size: 15px;
        }
    }

    .program-image {
        top: 0px;

        .program-image-like {
            top: 25px !important;
            right: 20px !important;
            z-index: 9999;
        }

        @media (max-width: $sm-max) {
            img {
                margin-top: 35px;
                scale: 150%;
                border-radius: unset;
            }
        }

        img {
            border-radius: 10px;
        }

    }

    .program-info {
        position: absolute;
        margin-top: 320px !important;
    }

    .back-button {
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        width: 43px;
        height: 43px;
        background: #fff;
        position: absolute;
        top: 17px;
        left: 17px;
        border-radius: 3px;

        svg {
            font-weight: bold;
            height: 55%;
            width: 55%;
        }
    }
}

.program-workouts {

    .program-title {
        font-size: 1.2rem;
        font-family: $poppins-medium;
    }

    .image-card {
        overflow: hidden;
        display: flex;
        justify-content: center;
        margin-top: 5px !important;
        height: 253px;

        @media (max-width: $sm-max) {
            height: auto;
        }

        img {
            // height: 100%;
            width: 100%;
            // margin-top: 55px;
            // margin-right: 35px;
        }
    }
}

.program-view-close {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 9999;
}

.tp-close {
	top: 26px;
	right: 26px;
}

@keyframes rotateAnimation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(90deg);
    }
}

.portrait {

    .controls {
        bottom: 0;
        // transform: rotate(90deg);
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    #progress-bar-container {
        position: absolute;
        background-color: #FFF;
        border-radius: 3px;
        width: 90vh;
        margin-top: 19vh;
    }

    #progress-bar {
        height: 4px;
        background-color: $color-primary;
        width: 0;
        border-radius: 3px;
    }

    .progress-bar {
        position: absolute;
        height: 92vh;
        top: 3.7vh;
        left: 12vw;
        // margin-top: 0 !important;
        // transform: rotate(-90deg);
        background-color: unset !important;
    }

    .center-controls {
        display: flex;
        justify-content: space-evenly;
        transform: rotate(90deg);
        width: 90vw;

        .scaled-high {
            scale: 1.2;
        }
    }

    .bottom-controls {
        position: absolute;
        width: 90vh;
        // height: 30px;
        margin-left: -83vw;
        // margin-top: 4vw;
        transform: rotate(90deg);
        display: flex;
        flex-direction: row;
        justify-content: space-between;


        .button-ctrl {
            height: auto;
            display: flex;
            align-items: center;
            flex-direction: row;

            p {
                color: #FFF;
                font-family: $poppins;
                font-size: 1.1rem;
                margin-top: 14px;
                text-transform: uppercase;
            }
        }
    }

    .upper-controls {
        position: fixed;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        top: 0;
        right: 15px;
        padding: 40px 0;
        height: 100vh;
        max-height: 100vh;
        z-index: 9999;
        // border: 1px solid red;

        .video-title {
            position: absolute;
            // border: 1px solid red;
            color: #ffffff;
            z-index: 9999;
            transform: rotate(90deg);
            margin-left: -192px;
            width: 400px;
            margin-top: 200px;

            p {
                font-family: $poppins !important;
                font-size: 1.4rem;
                white-space: nowrap;
            }
        }
    }

    .full-video-player-close {
        // position: absolute;
        // top: 20px;
        // right: 10px;
        // z-index: 9999;
        transform: rotate(90deg);
        // border: 1px solid red;

    }

    .full-video-chromecast-btn {
        // position: absolute;
        // bottom: 20px;
        // right: 10px;
        // z-index: 9999;
        transform: rotate(90deg);
        margin-top: 15px;
        // border: 1px solid red;


    }

    .full-video-pip-btn {
        transform: rotate(360deg) !important;
        margin-top: 15px;

    }

    .full-video-pip-btn {
        bottom: 60px !important;
    }

    .full-video-play-btn {
        // position: absolute;
        // top: 50vh;
        // bottom: 50vh;
        // z-index: 9999;
        margin: 0 40px;
    }
}

.landscape {
    .full-video-player-close {
        position: absolute;
        left: 10px;
        top: 10px;
        z-index: 9999;
    }

    .full-video-chromecast-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 9999;
    }

    // .full-video-play-btn {
    //     position: absolute;
    //     top: 50vh;
    //     z-index: 9999;
    // }

    .video-title {
        position: absolute;
        left: 40px;
        top: 10px;
        z-index: 9999;
        color: #ffffff;

        p {
            font-family: $poppins !important;
            font-size: 1.4rem;
            white-space: nowrap;
        }
    }

    .controls {
        align-items: center;
        display: flex;
    }

    .center-controls {
        position: absolute;
        left: 0;
        display: flex;
        justify-content: space-evenly;
        width: 100vw;
        z-index: 9999;

        .scaled-high {
            scale: 1.2;
        }
    }

    .progress-bar {
        // position: absolute;
        display: flex;
        justify-content: space-between;
        width: 100%;
        transform: rotate(-90deg);
        background-color: unset !important;
        margin-top: 60vh;
        height: 100vw;
    }

    .bottom-controls {
        position: absolute;
        padding: 0 10px 0 10px;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .button-ctrl {
            height: auto;
            display: flex;
            align-items: center;
            flex-direction: row;

            p {
                color: #FFF;
                font-family: $poppins;
                font-size: 1.1rem;
                margin-top: 14px;
                text-transform: uppercase;
            }
        }
    }
}
