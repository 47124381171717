// Bootstrap Imports
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import './fonts.scss';

@import "~react-datepicker/dist/react-datepicker.css";

//Variables
// Bootstrap Overrides
$enable-negative-margins: true;
$box-shadow: 0 0.5rem 1rem rgba($black, 0.2);
$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.1);
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.3);
$box-shadow-inset: inset 0 1px 2px rgba($black, 0.1);

/**
 * Variables
 */

//Fonts

$poppins: 'Poppins';
$poppins-bold: 'Poppins-Bold';
$poppins-semibold: 'Poppins-SemiBold';
$poppins-extrabold: 'Poppins-ExtraBold';
$poppins-medium: 'Poppins-Medium';
$poppins-light: 'Poppins-Light';
$poppins-extralight: 'Poppins-ExtraLight';
$poppins-thin: 'Poppins-Thin';

$overpass-medium: 'Overpass-Medium';
$overpass: 'Overpass';
$overpass-light: 'Overpass-Light';

// Space Units
$space-unit-xs: 0.125rem;
$space-unit-sm: $space-unit-xs * 2;
$space-unit-md: $space-unit-sm * 2;
$space-unit-lg: $space-unit-md * 2;
$space-unit-xl: $space-unit-lg * 2;

// Font Sizes
$h1-size: 2.25rem;
$h1-mobile-size: 1.5rem;
$h1-weight: 500;
$h1-spacing: 2px;
$h1-spacing-mobile: 1px;

$h2-size: 1.25rem;
$h2-mobile-size: 1rem;
$h2-weight: $h1-weight;
$h2-spacing: $h1-spacing;
$h2-spacing-mobile: $h1-spacing-mobile;

$h3-size: 1.5rem;
$h3-mobile-size: $h3-size;
$h3-weight: 700;
$h3-spacing: -1px;

$h4-size: 1.2rem;
// $h4-size: 1rem;
$h4-mobile-size: $h2-mobile-size;
$h4-weight: 400;

$h5-size: 1rem;
// $h5-size: 0.9rem;
$h5-mobile-size: 0.9rem;
$h5-weight: 500;

$h6-size: 0.9rem;
$h6-weight: 400;

$h7-size: 1.2rem;
$h7-weight: 400;
$h7-weight-mobile: 700;

$menu-text-size: 0.8rem;
$menu-text-mobile-size: 1rem;
$menu-text-weight: 400;
$menu-text-mobile-weight: 500;
$button-text-size: 0.9rem;
$button-text-mobile-size: 0.7rem;

$master-font-size: calc(0.75em + 0.5vw);
$content-font-size: 1rem;
$content-line-height: 1.7;
$no-line-height: 1.2;

// Breakpoints
$xxl-max: 1600px;
$xxl-middle: $xxl-max - 1px;
$xxl-min: 1400px;
$xl-max: $xxl-min - 1px;
$xl-min: 1200px;
$lg-max: $xl-min - 1px;
$lg-min: 992px;
$md-max: $lg-min - 1px;
$md-min: 768px;
$sm-max: $md-min - 1px;
$sm-min: 576px;
$xs-max: $sm-min - 1px;
$xs-min: 0;
$xxs-max: 410px;

$sm-w: 600px;
$md-w: 900px;
$lg-w: 1200px;
$xl-w: 1536px;

// Colors
$color-primary: #9fcd2b;
$color-secondary: #b7ff00;
$color-secondary-light: #c6fd50;
$color-tertiary: #742F4E;
$dark-card-bg: #1a1a12;
$dark-card-bg-secondary: #1a1a12a8;
$link-opacity-percent: 0.9;
$secondary-percent: 0.55;

// Cards
$detail-card-width: 625px;
$detail-card-mobile-height: 140px;
$mobile-card-width: 300px;
$small-card-height: 120px;
$medium-card-height: 162px;
$medium-card-width: 240px;
$large-card-max-width: 375px;
$large-card-height: 190px;
$new-card-max-width: 450px;

// Buttons
$circle-lg-size-desktop: 4.5rem;
$circle-lg-size-mobile: 4rem;
$circle-md-size: 3rem;
$home-play-button-sm-top: 35%;
$fixed-lg-width: 15rem;
$fixed-md-width: 10rem;
$fixed-sm-width: 8rem;
$nav-pill-button-width: 150px;
$mechanix-play-button-size: 32px;

// Icons
$arrow-icon-size: 3rem;
$arrow-icon-stroke: 3px;
$heart-icon-size: 2rem;
$heart-icon-metadata-size: 1.2rem;
$detail-heart-icon-adjust: 51.75px;

// Misc
$home-row-width: 1286px;
$max-container-width: 1520px;
$max-container-width-1170: 1170px;

$main-margin-top: 70px;
$main-margin-top-mobile: 70px;
$main-margin-top-mobile-sm: 60px;

$jumbotron-margin-top: 20px;
$jumbotron-margin-top-mobile: $jumbotron-margin-top - 5px;

$search-margin-top: $main-margin-top + 10px;
$search-margin-top-mobile: $main-margin-top-mobile + 20px;

$img-fill-height: 729px;
$img-fill-sm-height: 24rem;

$form-padding-md: 3rem;
$form-padding-lg: 12rem;

$card-lower-logo-top: 220px;
$invite-card-min-height: 350px;
$recurly-card-height: 24px;
$large-aspect-ratio: 24 / 10;
$default-aspect-ratio: 16 / 9;
$navbar-toggler-height: 38px;
$card-title-line-margin: 1.75rem;
$nav-menu-span-width: 29px;
$side-nav-menu-font-size: 2rem;
$side-nav-menu-width: 250px;
$side-nav-menu-item-height: 40px;
$responsive-text-card-margin: 20px;
$card-body-bottom-margin: 45px;
$card-body-line-height: 27px;
$video-detail-card-text-left: -36.5px;
$text-card-padding: 20px;

// Hero Image
$hero-image-width: 20rem;
$hero-image-md-width: 15rem;
$hero-image-sm-width: 11rem;

@mixin placeholder {
    &::-webkit-input-placeholder {
        color: #545454;
        font-weight: 400;
    }

    &:-moz-placeholder {
        color: #545454;
        font-weight: 400;
    }

    &::-moz-placeholder {
        color: #545454;
        font-weight: 400;
    }

    &:-ms-input-placeholder {
        color: #545454;
        font-weight: 400;
    }
}

@import 'bootstrap/scss/bootstrap';

@import './training.scss';
@import './download-plan.scss';
@import './plan-pdf.scss';
@import './onboarding-survey.scss';
@import './programs.scss';
// @import './Choose_plan.scss';
@import './checkout.scss';
@import './recover.scss';


/**
 * Globals
 */

html {
    overscroll-behavior: none;

    // Small Width
    @media (max-width: $sm-max) {
        font-size: $master-font-size;
    }
}

// Body
body {
    @extend .bg-main;
    width: 100%;
    font-family: 'Poppins';
    overflow-x: hidden;
    overscroll-behavior: none;
    -webkit-overflow-scrolling: touch;
}


// Headings
h1,
h2,
h3,
h4,
h6,
h6.is-h7 {
    font-family: $poppins !important;
}

h5 {
    font-family: $poppins-bold !important;
}

// Uppercase
h1,
h2,
h4,
.menu-1-text,
.menu-2-text {
    text-transform: uppercase;
}

// H1
h1 {
    font-size: $h1-size;
    font-weight: $h1-weight;
    letter-spacing: $h1-spacing;

    // Medium Screen
    @media (max-width: $md-max) {
        font-size: $h1-mobile-size;
        letter-spacing: $h1-spacing-mobile;
    }
}

// H2
h2 {
    font-size: $h2-size;
    font-weight: $h2-weight;
    letter-spacing: $h2-spacing;

    // Medium Screen
    @media (max-width: $md-max) {
        font-size: $h2-mobile-size;
        letter-spacing: $h2-spacing-mobile;
    }
}

// H3
h3 {
    font-size: $h3-size;
    font-weight: bold;
    letter-spacing: $h3-spacing;
}

// H4
h4 {
    font-size: $h4-size;
    font-weight: $h4-weight;
    text-transform: capitalize;

    // Medium Screen
    @media (max-width: $md-max) {
        font-size: $h4-mobile-size;
    }
}

// H5
h5 {
    font-size: $h5-size;
    font-weight: $h5-weight;
    font-family: $poppins !important;

    // Medium Screen
    @media (max-width: $md-max) {
        font-size: $h5-mobile-size;
    }
}

// H6
h6 {
    font-size: $h6-size;
    font-weight: $h6-weight;
}

// H7
h6.is-h7 {
    font-size: $h7-size;
    font-weight: $h7-weight;

    // Medium Screen
    @media (max-width: $md-max) {
        font-weight: $h7-weight-mobile;
    }
}

a {
    text-decoration: none;

    &:hover {
        color: inherit;
    }
}

// Menu 1 Text
.menu-1-text {
    font-family: 'Josefin Sans';
    font-size: $menu-text-size;
    font-weight: $menu-text-weight;

    // Medium Screen
    @media (max-width: $md-max) {
        font-size: $menu-text-mobile-size;
        font-weight: $menu-text-mobile-weight;
    }
}

// Menu 2 Text
.menu-2-text {
    @extend .menu-1-text;

    // Medium Screen
    @media (max-width: $md-max) {
        font-size: $menu-text-size;
    }
}

// Content

.form-control-lg {
    display: flex;
    align-items: center;
    align-content: space-between;
    width: 100%;
    min-height: 55px;
}

p,
div,
span,
div.form-control,
input.form-control,
select.form-select {
    font-family: $poppins;
    font-size: $content-font-size;
    line-height: $content-line-height;
}

// No Line Height
.no-line-height,
.no-line-height p,
.no-line-height div,
.no-line-height span,
.no-line-height h6 {
    margin-bottom: 0 !important;
    line-height: $no-line-height !important;
}

.form-input {
    font-family: $overpass-light !important;
}

.default-title {
    h2 {
        // @extend h2;
        font-family: $poppins-medium !important;
        text-transform: capitalize;
        font-size: 2rem;
        letter-spacing: 0px;
    }
}

.page-title-sm {
    @media (max-width: $sm-max) {
        display: none;
    }
}

// Page Title Div
.page-title-div {
    h2.page-title {
        margin-left: 50px;

        @media (max-width: $lg-min) {
            font-family: $poppins-medium !important;
            text-transform: capitalize;
            font-size: 1rem;
            letter-spacing: 0px;

        }
    }

    // Medium Screen
    @media (max-width: $md-max) {
        margin-bottom: $space-unit-lg * 1.5 !important;
    }
}

// App Root
#app-root {
    max-width: 100vw;
    overflow-x: hidden;
}

#app-root:has(> .training-plan-cont),
#app-root:has(> .login-v2) {
    margin-bottom: 0px !important;
}

/**
 * Custom Color Classes
 */

// Primary Color
.color-primary {
    color: $color-primary;
}

// Primary Color BG
.color-primary-bg {
    border-color: $color-primary;
    background-color: $color-primary;
    color: #010101;
}

.border-0 {
    border: none !important;
}

/**
 * Custom Spacing Classes
 */

// Top/Bottom Adjustments
.btm-1 {
    bottom: $space-unit-xs;
}

.top-1 {
    top: $space-unit-xs;
}

.btm-2 {
    bottom: $space-unit-sm;
}

.top-2 {
    top: $space-unit-sm;
}

.btm-3 {
    bottom: $space-unit-md;
}

.top-3 {
    top: $space-unit-md;
}

.btm-4 {
    bottom: $space-unit-lg;
}

.top-4 {
    top: $space-unit-lg;
}

.btm-5 {
    bottom: $space-unit-xl;
}

.top-5 {
    top: $space-unit-xl;
}

// Card Title Line
.card-title-line {
    margin-left: $card-title-line-margin;
}

// Right Corner Absolute
.right-corner-absolute {
    position: absolute;
    top: 15px;
    right: 15px;
}

// No Bottom Margin
.no-bottom-margin {
    margin-bottom: 0 !important;

    * {
        margin-bottom: 0 !important;
    }
}

// Account Secondary Margin
.account-secondary-margin {
    margin-right: $space-unit-lg * 8;
}


// Padding 4 Form
.px-form-4 {

    // Medium Width
    @media (min-width: $md-min) {
        padding-left: $form-padding-md * 0.75;
        padding-right: $form-padding-md * 0.75;
    }

    // Large Width
    @media (min-width: $lg-min) {
        padding-left: $form-padding-lg * 0.75;
        padding-right: $form-padding-lg * 0.75;
    }
}

// Padding 5 Form
.px-form-5 {

    // Medium Width
    @media (min-width: $md-min) {
        padding-left: $form-padding-md;
        padding-right: $form-padding-md;
    }

    // Large Width
    @media (min-width: $lg-min) {
        padding-left: $form-padding-lg;
        padding-right: $form-padding-lg;
    }
}

/**
 * Custom Layout Classes
 */

// Home Center Row
.home-center-text {

    margin-top: 20px !important;
    margin-bottom: 0px !important;

    // XXL Screen
    @media (min-width: $xxl-max) {
        padding-left: 0;
        width: $home-row-width;
    }
}

.home-center-row {

    // XXL Screen
    @media (min-width: $xxl-max) {
        width: $home-row-width;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }
}

.disable-scrollbars::-webkit-scrollbar {
    background: transparent;
    width: 0px;
}

.disable-scrollbars {
    scrollbar-width: none;
    -ms-overflow-style: none;
}

// Scroll Row
.rso-scroll-row {
    margin-bottom: $space-unit-lg;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    overflow-x: auto;
    overflow-y: hidden;
    cursor: pointer;

    // Active
    &.active {
        cursor: grabbing;
        cursor: -webkit-grabbing;
    }

    // Scrollbar
    &::-webkit-scrollbar {
        width: 0 !important;
        height: 0 !important;
    }

    // Container
    >div.scroll-container>div.scroll-element-container {
        flex: 0 0 auto;
        display: inline-flex;

        // Mobile Break Class
        &.mobile-break {

            // XS Width
            @media (max-width: $xs-max) {
                display: block;
            }

            // Elements
            >* {

                // XS Width
                @media (max-width: $xs-max) {
                    margin-right: 0;
                }
            }
        }
    }

    // Elements
    >div.scroll-container>div.scroll-element-container>* {
        flex: 0 0 auto;
        margin-right: $space-unit-xl;
    }
}

#favorites-home,
#history-home {
    .rso-scroll-row {
        .scroll-container {
            padding: 0 !important
        }

        >div.scroll-container>div.scroll-element-container>* {
            margin-right: 0px;
            padding-left: 16px;
            padding-right: 0px;
        }
    }
}

// Flex Content
.flex-content {
    flex: 1;
    display: flex;
}

.flex-content-column {
    flex: 1;
    display: flex;
    flex-direction: column
}

// Flex Content Start
.flex-content-start {
    @extend .flex-content;
    align-items: flex-start;
}

// Flex Content End
.flex-content-end {
    @extend .flex-content;
    align-items: flex-end;
}

// Flex Content Center
.flex-content-center {
    @extend .flex-content;
    align-items: center;
}

// Vertical Center Container
.vertical-center-container {
    // display: flex;
    align-items: center;
    // height: 100%;
}

// Full Width
.full-width-relative {
    position: relative;
    width: 100%;

    // XS Width
    @media (max-width: $sm-min) {
        width: 92%;
        left: 11px;
    }

}

/**
 * Custom Text and Form Classes
 */

.fw-bold {
    font-family: $poppins-bold;
}

.fw-medium {
    font-family: $poppins-medium !important;
}

.fw-light {
    font-family: $poppins-light;
}

.fw-regular {
    font-family: $poppins;
}

// Account Form
.account-form {

    // max-width: $mobile-card-width * 2;
    .fixed-md-btn {
        &.btn-dark {
            background: $black;
            opacity: 1;
        }

        @media (max-width: $sm-max) {
            padding: 10px;
            font-size: 15;
            width: 222px;
        }
    }

    .form-label {
        text-transform: none !important;
        font-family: $poppins-medium;

        @media (max-width: $sm-max) {
            text-transform: none !important;
        }
    }

    .month-annuall-bill {
        font-family: $poppins-medium !important;
        font-style: italic;
    }

    .saved--text {
        color: #f77d61;
    }

    .promo-code-label {
        font-weight: 300;
    }

    .promocode-input {
        width: 366px;

        @media (max-width: $md-min) {
            width: 100%;
        }
    }

    .form-control {
        @include placeholder();
        // &::placeholder {
        // 	@include placeholder();
        // }
    }
}

// Account Form Large
.account-form-lg {
    max-width: $mobile-card-width * 2.75;
}

.subscription-form {
    h5 {
        font-family: $poppins-semibold;
        text-transform: uppercase;
    }

    div {
        font-family: $overpass-light;
    }
}

/**
 * Custom Button and Icon Classes
 */

// Back Button
.back-btn {
    position: absolute;
    margin-left: 20px;
    margin-top: -0.3rem;

    @media (max-width: $md-min) {
        margin-left: -100px;
    }

}

// Back Button
.back-btn-misc {
    position: absolute;
    margin-left: 0px;
    margin-top: -50px;
    border: 1px solid #DDD;
    padding: 5px;
    border-radius: 4px;
    background-color: #FFF;
}

.pay {
    svg {
        margin-right: -4px !important;
        margin-left: -1px !important;
        margin-top: -6px !important;
    }
}

.button-icon {
    width: 200px !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;

    span {
        font-family: $poppins;
        font-size: 0.9rem;
    }

    svg {
        margin-right: 15px;
        margin-left: 3px;
    }

    @media (max-width: $sm-max) {
        width: 100% !important;
    }
}

.checkout-buttons .button-icon {
    width: 100% !important;
}

.other-login {
    display: flex;
    align-items: center;
    flex-direction: column;

    @media (max-width: $sm-max) {
        flex-direction: column;
    }
}

// Icon Circle Button Large
.icon-btn-circle-lg {
    width: $circle-lg-size-desktop;
    height: $circle-lg-size-desktop;

    // Small Width
    @media (max-width: $sm-max) {
        width: $circle-lg-size-mobile;
        height: $circle-lg-size-mobile;
    }
}

// Circle Button Large
.btn-circle.btn-lg {
    @extend .icon-btn-circle-lg;
    border-radius: $circle-lg-size-desktop / 2;
    text-align: center;

    // Small Width
    @media (max-width: $sm-max) {
        border-radius: $circle-lg-size-mobile / 2;
    }
}

// Icon Circle Button Medium
.icon-btn-circle-md {
    width: $circle-md-size;
    height: $circle-md-size;
}

// Circle Button Medium
.btn-circle.btn-md {
    @extend .icon-btn-circle-md;
    border-radius: $circle-md-size / 2;
    text-align: center;
}

// Fixed Buttons
.fixed-btn {

    // Small Width
    @media (max-width: $xs-max) {
        min-width: unset !important;
    }
}

// Fixed Small Button
.fixed-sm-btn {
    @extend .fixed-btn;
    min-width: $fixed-sm-width;
}

// Fixed Medium Button
.fixed-md-btn {
    @extend .fixed-btn;
    min-width: $fixed-md-width;
}

// Fixed Large Button
.fixed-lg-btn {
    @extend .fixed-btn;
    min-width: $fixed-lg-width;
}

// Nav Pills
.rs-nav.nav-pills .rounded-pill.nav-item {
    min-width: $nav-pill-button-width;
    text-align: center;
    white-space: nowrap;

    // border: 4px solid rgba(var(--bs-dark-rgb)) !important;

    // Small Width
    @media (max-width: $sm-max) {
        width: unset;
        min-width: $nav-pill-button-width * 0.5;

        // Link
        a.rounded-pill {
            padding: 0.5rem 0.5rem;
        }
    }
}

.phone-video-list {
    .btn-circle {
        // height: fit-content;
        width: 33px !important;
        height: 33px !important;
        padding: 3px;
    }

    .video-detail-card-text {
        width: auto !important;

        .text-secondary {
            width: auto;
            margin-left: 23px;
        }

        .text-dark {}
    }

    .home-detail-card {
        height: fit-content;
        width: auto;
    }

    .card-image-container {
        width: 150px;
        min-width: 150px;
        height: 110px;
        border-radius: 10px;
        overflow: hidden;
    }

    img {
        scale: 1.6;
        margin-top: 18px;
    }
}

.account-nav-main {
    .rs-nav {
        flex: none !important;
        background-color: rgba(var(--bs-dark-rgb)) !important;

        @media (max-width: $sm-max) {
            flex: auto !important;
        }
    }
}

.account-nav {
    width: auto;
    height: auto;

    min-width: $nav-pill-button-width;
    text-align: center;
    white-space: nowrap;
    border: 4px solid rgba(var(--bs-dark-rgb)) !important;

    // Link
    .nav-link {
        color: white !important;
        font-family: $poppins-medium !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    // Active Link
    .nav-link.active {
        @extend .color-primary-bg;
    }

    // Small Width
    @media (max-width: $sm-max) {
        width: unset;
        min-width: $nav-pill-button-width * 0.45;

        // Link
        a.rounded-pill {
            padding: 0.5rem 0.5rem;
        }
    }

    @media (max-width: $sm-max) {
        width: 120%;
    }
}

.update-pass--title {
    font-family: $poppins !important;
    font-size: 18px;
    width: 100%;
    text-align: center;
}

.billing--title {
    font-family: $poppins-bold !important;
    font-size: 18px !important;
    width: 100% !important;
    margin-left: 0px;
    // @media (max-width: $sm-max) {
    //     text-align: center;
    // }
}

.billing-details {

    h5 {
        text-transform: capitalize !important;
        font-family: $poppins-medium !important;
    }

    div {
        font-family: $overpass-light;
    }


    margin-left: -10px;

    @media (max-width: $sm-max) {
        margin-left: -10px;
    }
}

.billing-btn {
    // @media (min-width: $md-min) {
    //     display: flex;
    //     justify-content: center;
    //     margin-top: 0px;
    //     margin-right: 80px;
    // }
}

.s-btn {
    margin: 0px 10px !important;
}

.account-primary-button {
    // /* Layout Properties */
    width: 220px;
    display: block;
    // height: 60px;
    // /* UI Properties */
    // // background: #742F4E 0% 0% no-repeat padding-box !important;

    // background: #742F4E 0% 0% no-repeat padding-box !important;
    // border-radius: 16px;
    // opacity: 1;
    // font-family: $poppins-medium !important;
    // color: #fff;

    // &:hover {
    //     background: #742F4E 0% 0% no-repeat padding-box !important;
    //     color: #f3f2f2 !important;
    // }

    // @media (max-width: $sm-max) {
    //     height: 50px;
    // }
    border: 1px solid #D8D8D8;
    border-radius: 46px;
    opacity: 1;
    margin: 10px auto -10px auto;
}

.disabled-button {
    /* Layout Properties */
    width: 220px;
    // height: 60px;
    /* UI Properties */
    // background: #742F4E 0% 0% no-repeat padding-box !important;

    background: #d9d9d9 0% 0% no-repeat padding-box !important;
    // border-radius: 16px;
    border-radius: 46px;
    opacity: 1;
    border: none !important;
    font-family: $poppins-medium !important;
    color: #aaaaaa !important;

    @media (max-width: $sm-max) {
        // height: 50px;
    }
}

.account-buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: $sm-max) {
        flex-direction: column;
    }

    button {
        margin-right: 0px;
        margin-top: -10px;

        // @media (max-width: $sm-max) {
        //     margin-right: 0px;
        //     margin-top: -10px;
        // }
    }
}

.account-secondary-button {
    /* Layout Properties */
    width: 232px;
    height: 60px;
    /* UI Properties */
    background: #1a1a12;

    border-radius: 16px;
    opacity: 1;
    font-family: $poppins-medium !important;
    color: #fff;

    // &:hover {
    //     background: #742F4E 0% 0% no-repeat padding-box !important;
    //     color: #f3f2f2 !important;
    // }

    @media (max-width: $sm-max) {
        height: 50px;
    }
}

.account-title {

    h3 {

        font-family: 'Poppins-SemiBold' !important;
        letter-spacing: 0.3px !important;
    }

    width: 280px;
    margin-right: -120px;
    // background: #545454;
    text-align: center;

    @media (max-width: $sm-max) {
        display: none;
    }
}

.extra-button {
    /* UI Properties */
    background: var(---ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029;
    border: 1px solid #D8D8D8;
    border-radius: 46px;
    opacity: 1;
}

.extra-label {
    @media (max-width: $sm-max) {
        display: none;
    }
}

.choices-label {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-weight: bold;
}

.select-pill {
    text-transform: uppercase;

    @media (max-width: $sm-max) {
        display: flex;
        flex: 1 1 0;
        flex-direction: column;
    }
}

// Rounded Pill Small Text
.rounded-pill.small-text {
    font-size: $h5-mobile-size;
}

.rounded-pill {
    h5 {
        font-family: 'Poppins-Bold' !important;
    }
}

.squared-pill {
    border-radius: 4px !important;

    .nav-link {
        color: #1b1c20;
        font-family: $poppins-medium !important;
        margin-left: 2px !important;
        margin-right: 2px !important;
        background-color: #FFFFFF;
        min-width: 110px;
        text-align: center !important;

        @media (max-width: $sm-max) {
            padding-left: 2px !important;
            padding-right: 2px !important;
        }
    }
}

.squared-pill--pay {
    font-size: 16px;
    border-radius: 4px !important;
    width: 240px !important;
    height: 40px !important;
    text-align: center !important;
    padding: 5px 0 5px 0;
    margin: 5px;
}

.rounded-pill--pay {
    border-radius: 100vh;
    width: 240px !important;
    height: 40px !important;
    text-align: center !important;
    padding: 5px 0 5px 0;

    span {
        @media (max-width: $sm-min) {
            font-size: 1.2rem;
        }

        margin: 0 auto;
        font-size: 1rem;
        font-family: $poppins;
    }

}

.rounded-pill--signup {

    @extend .rounded-pill;

    @media (max-width: $sm-max) {
        font-family: 'Poppins' !important;
        padding: 10px;
        width: 120px;
        text-transform: uppercase;
    }
}

.append-info {
    font-weight: bold;
    margin-top: 0.5rem;
    margin-bottom: 1.3rem;
    margin-left: 0.3rem;
}

.chosen-plan-info {
    max-width: 420px;
    width: 100%;
    display: flex;
    margin: 0 auto;

    p {
        margin: 0 auto;
        color: #6f6f6f;
        font-family: $overpass-light;
        text-align: center;
        font-size: 1rem;
    }
}

// Play Button
.play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

// Toggle Card Button
.toggle-card-button {
    position: absolute;
    bottom: $space-unit-lg;
    right: $space-unit-lg;
}

// Card Play Lower
.card-play-lower {
    position: absolute;
    left: $space-unit-lg;
    bottom: $space-unit-lg;
}

// Home Play Button
.home-play-btn {
    @extend .play-btn;

    // Small Width
    @media (max-width: $sm-max) {
        top: $home-play-button-sm-top;
        transform: translate(-50%, -50%);
    }
}

// Home Action Button
.home-action-btn {

    // Medium Width
    @media (max-width: $md-max) {
        padding: ($space-unit-sm * 1.5) ($space-unit-md * 1.5);
        font-size: $space-unit-lg;
        border-radius: $space-unit-sm;
    }
}

// Arrow Icon
.arrow-icon {
    overflow: visible;
    width: $arrow-icon-size;
    height: $arrow-icon-size;

    // Child Path
    >path {
        fill: transparent;
        stroke: $color-primary;
        stroke-width: $arrow-icon-stroke;
        stroke-linejoin: miter;
        stroke-linecap: round;
        stroke-miterlimit: 4;
        shape-rendering: auto;
    }
}

// Heart Icon
.heart-icon {
    @extend .right-corner-absolute;
    cursor: pointer;
    width: 30px;

    // SVG
    >svg {
        filter: drop-shadow($box-shadow-sm);
        // color: #dc3545;
        width: $heart-icon-size !important;
        height: $heart-icon-size !important;
    }

    // Video Card Icon
    &.video-card-icon {
        top: $space-unit-lg;
        right: $space-unit-lg;
    }

    // Video Detail Card Icon
    &.video-detail-card-icon {
        top: 5px;
        right: 5px;
    }

    // Video Detail Card Icon
    &.video-detail-card-icon-default {
        top: $detail-heart-icon-adjust - 0px;
        left: $detail-heart-icon-adjust + 20px;
    }


}

.heart-icon-not-abs {
    cursor: pointer;
    width: 30px;

    // SVG
    >svg {
        filter: drop-shadow($box-shadow-sm);
        // color: #dc3545;
        width: $heart-icon-size !important;
        height: $heart-icon-size !important;
    }
}

.heart-metadata {
    width: 20px;

    >svg {
        filter: drop-shadow($box-shadow-sm);
        // color: #dc3545;
        width: $heart-icon-metadata-size !important;
        height: $heart-icon-metadata-size !important;
    }
}

.heart-metadata-not {
    width: 20px;

    >svg {
        filter: drop-shadow($box-shadow-sm);
        // color: #dc3545;
        width: $heart-icon-metadata-size !important;
        height: $heart-icon-metadata-size !important;
    }
}

.heart-metadata-unlike {
    >svg {
        filter: none !important;
        stroke: gray !important;
    }
}

.card-logo {
    max-width: 50%;
    position: absolute;
}

// Card Logo Lower
.card-logo-lower {
    @extend .card-logo;
    top: $card-lower-logo-top;
}

// Card logo Center
.card-logo-center {
    @extend .card-logo;
}

// Large Icon
.icon-lg {
    transform: scale(2);
}

// XL Icon
.icon-xl {
    transform: scale(2.5);
}

/**
 * Custom Card Classes
 */

// Home Detail Card
.home-detail-card {
    width: $new-card-max-width;
    height: $medium-card-height;
    background-color: #eeeee4;

    // Medium Width
    @media (max-width: $md-max) {
        height: $detail-card-mobile-height;
    }

    // XS Width
    @media (max-width: $new-card-max-width) {
        width: 100%;
    }

    // Card Text
    .card-text {
        display: flex;
        flex-grow: 1;
    }
}

// Home Detail Card Body
.home-detail-card-body {
    width: 130px;
    height: 120px;
    margin-left: $space-unit-lg;

    // Medium Width
    @media (max-width: $md-max) {
        margin-top: 0px;
    }

    // Medium Width
    @media (max-width: $sm-max) {
        height: 112px;
        margin-top: 0px;
    }
}

// Home Detail Card Text
.home-detail-card-text {
    width: 100% !important;
    flex-grow: 1;
}

// Video Detail Card Text
.video-detail-card-text {
    @extend .home-detail-card-text;
}

// Card Link
.card-link {
    text-decoration: none;
    color: white;

    // Card
    .card {
        border: none;
    }

    .card-clear {
        background-color: transparent !important;
    }
}

// Image Link
.img-link img,
.img-link svg {
    position: relative;
    top: -2px;
}

// Home Detail Card
.home-detail-card .card-img-overlay,
.home-detail-card .card-text {
    @extend .vertical-center-container;
}

// Small Card
.small-card {
    @extend .home-detail-card;
    height: $small-card-height;
    width: $large-card-max-width;

    // Medium Width
    @media (max-width: $md-max) {
        height: $small-card-height;
        width: 100%;
    }
}

// Large Card
.large-card {
    @extend .home-detail-card;
    aspect-ratio: $default-aspect-ratio;
    width: $large-card-max-width;

    // XS Width
    @media (max-width: $xs-max) {
        width: $mobile-card-width;
    }
}

// Video Card
.video-card {
    @extend .home-detail-card;
    height: $large-card-height;
    width: $mobile-card-width;
}

// Video Card Large
.video-card-lg {
    @extend .home-detail-card;
    height: $large-card-height * 1.5;
    width: $mobile-card-width * 1.5;

    // Medium Width
    @media (max-width: $md-max) {
        height: $large-card-height;
        width: $mobile-card-width;
    }
}

// Video Card Large Stretch
.video-card-lg-stretch {
    @extend .video-card-lg;

    // Medium Width
    @media (max-width: $md-max) {
        height: $large-card-height;
        min-width: $large-card-height * $default-aspect-ratio;
        width: 100%;
    }
}

// Responsive Card
.responsive-card {
    aspect-ratio: $default-aspect-ratio;
    width: 100%;
}

// Responsive Card Large
.responsive-card-lg {
    aspect-ratio: $default-aspect-ratio;
    width: 70%;
    margin: auto !important;

    // Large Width
    @media (max-width: $lg-max) {
        aspect-ratio: $default-aspect-ratio;
        width: 100%;
        height: unset;
    }
}

// Response Card XL
.responsive-card-xl {
    height: $mobile-card-width * 1.75;
    width: 100%;
    border: none;

    // Medium Width
    @media (max-width: $lg-max) {
        height: $mobile-card-width * 1.25;
    }

    // Small Width
    @media (max-width: $sm-max) {
        aspect-ratio: $default-aspect-ratio;
        height: unset;
        min-height: $mobile-card-width;
        max-width: 100%;
    }

}


// Response Card XL
.responsive-card-full {
    height: $mobile-card-width * 1.75;
    width: 100%;

    // Medium Width
    @media (max-width: $lg-max) {
        height: $mobile-card-width * 1.25;
    }

    // Small Width
    @media (max-width: $sm-max) {
        aspect-ratio: $default-aspect-ratio;
        height: unset;
        min-height: $mobile-card-width * 0.5;
        min-width: 110%;
        margin-left: -10px;

        img {

            border-radius: 0px !important;
        }
    }

}

// Response Card Fill
.responsive-card-fill {
    height: 100%;
    width: 100%;
}

// Card No Overflow
.card-no-overflow {
    padding: $text-card-padding;

    .card-body {
        line-height: $card-body-line-height;
        overflow-y: hidden;

        .is-clamped,
        .is-expanded {
            margin-bottom: $card-body-bottom-margin;
        }
    }

    // Small Width
    @media (max-width: $sm-max) {
        margin-left: $responsive-text-card-margin;
        margin-right: $responsive-text-card-margin;
    }

    // XS Width
    @media (max-width: $xs-max) {
        margin-left: unset;
        margin-right: unset;
    }
}

// Card No Aspect
.card-no-aspect {
    aspect-ratio: unset !important;
}

// Responsive Text Card
.responsive-text-card {
    @extend .card-no-overflow;
    height: fit-content;
}

// Responsive Text Card Large
.responsive-text-card-lg {
    @extend .card-no-overflow;
    height: fit-content;
}

// Response Text Card XL
.responsive-text-card-xl {
    @extend .card-no-overflow;
    @extend .responsive-card-xl;
    width: unset;
    height: fit-content;
}

// Responsive Text Card Fit
.responsive-text-card-fit {
    width: $mobile-card-width;

    // Card Body
    .card-body {
        padding: $space-unit-sm $space-unit-sm;
        padding-top: $space-unit-md;
    }
}

// Invite Card
#invite-card .card-text {
    min-height: $invite-card-min-height;
}

// Card Fixed Body Height
.card-fixed-body-height>div:first-child {
    min-height: 220px;

    // Medium Width
    @media (max-width: $lg-max) {
        min-height: 300px;
    }

    // Small Width
    @media (max-width: $sm-max) {
        min-height: unset;
    }
}

/**
 * Misc. Custom Classes
 */

// Has Pointer
.has-pointer {
    cursor: pointer;
}

// Main Container
.main-container {

    p,
    div {
        font-family: $poppins-light;
        font-size: $content-font-size;
        line-height: $content-line-height;

    }

    margin-top: $main-margin-top;
    margin-bottom: $space-unit-xl * 1.5;

    // Medium Width
    @media (max-width: $md-max) {
        margin-top: $main-margin-top-mobile;
        margin-bottom: $space-unit-lg * 1.5 !important;
        padding-top: $space-unit-lg * 1.5 !important;
    }

    // Small Width
    @media (max-width: $sm-max) {
        margin-top: $main-margin-top-mobile-sm;
    }
}

// Search Row
.search-row {
    margin-top: -1rem;
    margin-bottom: -3rem;

    // Medium Width
    @media (max-width: $md-max) {
        margin-top: 0rem;
        margin-bottom: -1rem;
    }

    @media (max-width: $sm-max) {
        position: relative;
        z-index: 10;
    }

    @media (max-width: $sm-min) {
        position: relative;
        z-index: 10;
    }
}

// Nav Pills
.rs-nav {
    padding-top: 0 !important;
    background-color: transparent !important;
    flex: 1 0 auto;
    justify-content: center;

    // Link
    .nav-link {
        color: #1b1c20;
        font-family: $poppins-medium !important;
        margin-left: 10px;
        margin-right: 10px;

        @media (max-width: $sm-max) {
            padding-left: 10px !important;
            padding-right: 10px !important;
        }
    }

    // Active Link
    .nav-link.active {
        @extend .color-primary-bg;
    }
}

// Nav Menu Span
.nav-menu-span {
    width: $nav-menu-span-width;
}


.visible {
    /* display: flex; */
    top: -1px;
    transition: top 0.5s ease-out;
}

.hidden {
    /* display: none; */
    top: -60px;
    transition: top 0.5s ease-out;
}


// Side Nav Menu Close
.side-nav-menu-close {
    font-size: $side-nav-menu-font-size;
    background: none;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 20px;
}

// Side Nav Menu
.side-nav-menu {
    top: 59px;
    right: -100%;
    background-color: rgba(256, 256, 256, 0.97);
    width: $side-nav-menu-width + 100;
    height: 100vh;
    display: flex;
    position: absolute;
    justify-content: center;
    transition: 200ms;
    z-index: 1050;
    // width: 100%;
    overflow-y: auto;
    padding-top: 20px;

    // Small Screen
    @media (max-width: $md-max) {
        background-color: #FFF;
        left: -100%;
        width: 100%;
        top: 0;
        height: 100vh;
    }

    // Active
    &.active {
        right: 0;
        bottom: 0;
        position: fixed;
        transition: 200ms;

        @media (max-width: $md-max) {
            left: 0;
            right: 0;
            bottom: 0;
            position: absolute;
            transition: 200ms;
            overflow-y: auto;
        }
    }

    .search-text {

        // Small Screen
        @media (max-width: $md-max) {
            input[type=text] {
                width: 93%;
                padding: 10px 10px 10px 40px !important;
                float: right;
            }
        }

    }

    .search-icon {
        z-index: 999;
        margin-top: -38px !important;
        margin-left: 7px !important;
        float: left;
    }
}

.no-border {
    border: unset !important;
}

.nav-item-title {
    width: 100%;
    font-size: 1rem;
    padding-bottom: 0px;
    margin-top: 30px !important;

    // Small Screen
    @media (max-width: $md-max) {
        margin-top: 30px !important;
        font-size: 1.25rem !important;
    }
}

.flex-column-items {
    display: flex;
    flex-direction: column;
}

.bottom-logo {
    margin: 0px 10px 10px -20px;
    text-align: center;
    height: 70px;

    // Small Screen
    @media (max-width: $md-max) {
        height: 160px;
    }
}

.menu-items {
    width: 96% !important;
    margin-top: -6px;

    span {
        font-family: $poppins !important;
    }

    // Small Screen
    @media (max-width: $md-max) {
        margin-top: 0;
        display: unset;
        flex-direction: unset;
    }
}

// Side Nav Menu Items
.side-nav-menu-items {
    width: 100%;

    li {
        list-style: none;
    }
}

// Side Nav Menu Item
.side-nav-menu-item {
    @extend .menu-2-text;
    // height: $side-nav-menu-item-height;
    margin-left: $space-unit-md * -1;
    border-bottom: 1px solid #ddd;
    width: 95%;
    padding-top: 10px;
    padding-bottom: 4px;

    // Small Screen
    @media (max-width: $md-max) {
        padding-top: 8px;
        padding-bottom: 6px;
    }

}

// Side Nav Menu Child Item
.side-nav-menu-child-item {
    @extend .menu-2-text;
    color: black;

    >a {
        text-decoration: none;
    }
}

// Side Nav Menu Toggle
.side-nav-menu-toggle {
    margin-left: $space-unit-md * -1;
    width: 100%;
    height: $navbar-toggler-height;
    display: flex;
    justify-content: start;
    align-items: center;
}

// Black Link
.black-link {
    color: black;

    // Hover
    &:hover,
    &:active,
    &:focus {
        opacity: $link-opacity-percent;
    }
}

// Navbar Links
.navbar-light .navbar-nav .nav-link,
.side-nav-menu-item .nav-link {
    @extend .black-link;

    // Image
    img {
        @extend .black-link;
    }
}

// Bottom Navbar
#rs-btm-navbar {
    z-index: 1029;
    display: none;
    padding-bottom: 0px;

    .nav-link {
        width: auto;
        padding: 0;
        text-align: center;
        margin-left: 2px;
        margin-right: 2px;
        font-size: 0.7rem !important;
        text-transform: uppercase;

    }

    // Small Screen
    @media (max-width: $md-max) {
        display: block;
    }
}

// Image Fill
.img-fill {
    // @extend .shadow-lg;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

// Account Nav Link
.account-nav-link {
    color: black;
    text-decoration: none;

    // Action
    &:hover,
    &:active,
    &:focus {
        color: $dark;
    }
}

// Account Nav Link Margin
.account-nav-link-margin {
    margin-right: $space-unit-lg * 8;

    // Medium Width
    @media (max-width: $md-max) {
        margin-right: $space-unit-lg * 5;
    }
}

// RS Frame
.rs-frame {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

// Mechanix Status Play Button
.mechanix-status-play-btn {
    width: $mechanix-play-button-size;
    height: $mechanix-play-button-size;

    // Path
    &.selected>path {
        fill: white;
    }

    &.not-selected>path {
        fill: $secondary;
    }
}

// IFrame Player
.iframe-player {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
}

// Recurly CC
#recurly-cc {
    max-height: $recurly-card-height;
    height: 33px;
}

// Recurly Element
.recurly-element.recurly-element-card iframe {
    height: $recurly-card-height !important;
}

.recurly-element-card {
    font-size: 44px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

}

.recurly-hosted-field-input-number {
    font-size: 44px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

/**
 * Hero Component
 */

// Jumbotron
.jumbotron {
    margin-top: $jumbotron-margin-top;
    // margin-bottom: $space-unit-xl * 1.5;
    width: 100%;

    // Medium Width
    @media (max-width: $md-max) {
        margin-top: $jumbotron-margin-top-mobile;
    }

    @media (max-width: $md-min) {
        width: 110%;
        margin-left: -15px;
        margin-top: 14px;
    }


    // Child Div
    >div.img-fill {
        display: flex;
        padding: $space-unit-xl;
        border-radius: $space-unit-sm + 0.2;
        aspect-ratio: $large-aspect-ratio;
        max-width: 100%;

        // Large Width
        @media (max-width: $lg-max) {
            aspect-ratio: $default-aspect-ratio;
        }

        // Small Width
        @media (max-width: $sm-max) {
            padding: $space-unit-lg;
            min-height: 200px;
        }
    }

    // Grand-Child Div
    >div.img-fill>div {
        width: 100%;
        display: flex;
        position: relative;
    }
}

// Hero Image
.hero-image {
    max-width: $hero-image-width;

    // Medium Width
    @media (max-width: $md-max) {
        max-width: $hero-image-md-width;
    }

    // Small Width
    @media (max-width: $sm-max) {
        max-width: $hero-image-sm-width;
    }
}

// Header Progress Bar Animation
@keyframes rso-header-progress-bar {
    0% {
        width: 0%;
    }
}

// Header/Video Progress Div
.rso-header-progress,
.rso-video-progress {
    &>div {
        background-color: $color-secondary;
        animation: rso-header-progress-bar 0.5s linear;
        animation-timing-function: ease-in-out;
        border-top-right-radius: $space-unit-md;
        border-bottom-right-radius: $space-unit-md;

        height: 8px;

        @media (max-width: $sm-max) {
            height: 5px !important;

        }

    }
}

// Header Progress
.rso-header-progress {
    background-color: black;
    position: absolute;
    bottom: 0;
    width: 100%;
    border-bottom-left-radius: $space-unit-md;
    border-bottom-right-radius: $space-unit-md;

    // Child Div
    &>div {
        height: $space-unit-lg;
    }
}

// Video Progress
.rso-video-progress {
    @extend .rso-header-progress;

    // Child Div
    &>div {
        height: $space-unit-md;
    }
}

// Chatbot
.roko-instabot-widget-button {

    // Medium Width
    // @media (max-width: $md-max) {
    display: none !important;
    // }
}

/**
 * Chromecast Styles
 */

.cc-link {
    position: absolute;
    bottom: 1px;
    right: 80px;
    z-index: 100;
}

.cc-btn {
    height: auto;
    border: 0;
    max-width: 30px;
    margin: 10px;
}

.cc-hide {
    display: none;
}

.cc-tuck {
    z-index: -1 !important;
}

/**
 * Bootstrap Customization
 */

// Card Image
.card-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

// No Border Radius
.card-no-br {
    @media (max-width: $sm-min) {

        .card,
        .card-img {
            border-radius: 0 !important;
        }
    }

}

// Card
.card,
.card .card-img {
    // @extend .shadow;
    border-radius: $space-unit-md;
}

// Card Dark
.card.bg-dark {
    background-color: $dark-card-bg !important;
}

// Navbar
.navbar {
    @extend .bg-white;
    @extend .shadow-sm;

    .nav-item span.d-inline {
        @extend .menu-1-text;
    }
}

// Navbar Container
.navbar>.container {
    .dropdown-toggle {

        .nav-menu-span {

            text-align: right !important;

            img {
                width: 20px !important;
            }

            svg {
                width: 18px !important;
            }
        }
    }

    .dropdown-menu {
        padding: 0;
    }

    .dropdown-item {
        font-size: 0.7rem;
        font-family: 'Josefin Sans', sans-serif;
        color: #6c757d !important;
        text-transform: uppercase !important;
        padding: 7px;

        @media (max-width: $sm-max) {
            padding: 9px 13px;
        }

        &:hover {
            // font-weight: 700;
            background-color: #9FCD2B;
            color: #ffffff !important;
        }

        &.active,
        &:focus {
            background-color: #9FCD2B;
            color: #ffffff !important;
        }
    }


    // Medium Width
    @media (max-width: $md-max) {
        max-width: 100%;
    }
}

// Navbar Brand
.navbar-brand {
    @media (max-width: $md-max) {
        transform: translateX(-50%);
        left: 50%;
        position: absolute;
    }
}

// Navbar Toggler
.navbar-toggler {
    border: 0;

    &:hover,
    &:active,
    &:focus {
        border: 0;
        box-shadow: unset;
    }
}

// Navbar Hide Btn
.navbar-hide-btn {
    .navbar-toggler {
        cursor: unset;
        height: $navbar-toggler-height;
    }

    .navbar-toggler-icon {
        display: none;
    }
}

// Container
.container-xxl,
.container-xl,
.container-lg,
.cointainer-md,
.container-sm,
.container {
    @media (min-width: $xxl-max) {
        max-width: $max-container-width;
    }
}

// Card Image
.card-img {
    height: 100%;
}

// Card Links
a .card {
    text-decoration: none;
}

// Buttons
button,
.nav-pills a {
    font-family: $poppins;
    font-weight: $h5-weight;
    font-size: $button-text-size;
}

// Alerts
.alert {
    margin-top: $main-margin-top + 10px;
    margin-bottom: calc($main-margin-top * -1) + 20px;
    min-width: 20rem;
    max-width: 28.6rem;
    z-index: 2000 !important;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    @media (max-width: 21.5rem) {
        left: 5%;
        transform: translateX(-2.5%);
        width: 95%;
        min-width: unset;
    }
}

// Alert Container
.alert-container {
    padding-left: $space-unit-md;
    padding-right: $space-unit-md;
}

// Buttons
.btn:focus,
.btn:active {
    outline: none !important;
    box-shadow: none !important;
}

// Slick
.slick-prev,
.slick-next {
    color: black !important;

    &:hover,
    &:focus {
        color: black !important;
    }
}

// Slick Prev
.slick-prev {
    left: -5px !important;
}

// Slick Next
.slick-next {
    right: -8px !important;
}

// Global
.font-50 {
    font-size: 50px;
}

.font-32 {
    font-size: 32px;
}

.font-10 {
    font-size: 10px;
}

.font-14 {
    font-size: 14px;
}

.font-15 {
    font-size: 15px;
}

.font-18 {
    font-size: 18px;
}

.font-20 {
    font-size: 20px;
}

.font-24 {
    font-size: 24px;
}

.font-28 {
    font-size: 28px;
}

.font-md-12 {
    @media (max-width: $sm-max) {
        font-size: 12px !important;
    }
}

.font-md-15 {
    @media (max-width: $sm-max) {
        font-size: $h2-mobile-size;
    }
}

.font-md-18 {
    @media (max-width: $sm-max) {
        font-size: 18px;
    }
}

.font-md-30 {
    @media (max-width: $sm-max) {
        font-size: 30px;
    }
}

.font-80 {
    font-size: 80px;

    @media (max-width: $lg-max) {
        font-size: 50px;
    }

    @media (max-width: $sm-max) {
        font-size: 18px;
    }
}

.container-center {
    @media (max-width: $sm-max) {
        margin-top: 50px;
    }
}

.text-primary {
    color: $color-primary !important;
}

// .text-secondary {
// 	color: $color-secondary !important;
// }

// .bg-dark {
// 	background-color: $dark-card-bg-secondary !important;
// }

.radius-5 {
    border-radius: 5px;
}

.radius-10 {
    border-radius: 10px;
}

.radius-15 {
    border-radius: 15px;
}

.radius-50 {
    border-radius: 50px;
}

.letter-space {
    letter-spacing: 0.5px;
}

.mw-400 {
    max-width: 400px;
}

.mw-170 {
    max-width: 170px;
}

.text-gray {
    color: #5a5a5a !important;
}

.text-gray-bold {
    color: #848282 !important;
}



.input-group {
    // .input-date {
    //     @media (max-width: $sm-min) {
    //         width: 217% !important;
    //         flex: none;
    //     }

    // }

    .btn-outline-secondary {
        border: 1px solid #ced4da;
        border-left: none;
    }

    input[type=password] {
        margin-right: 0;
        border-right: none;
    }

    .form-control.is-invalid {
        border: 1px solid #dc3545;
    }
}



.lh-lg {
    line-height: 1.8;

    @media (max-width: $sm-max) {
        line-height: 1.5 !important;
    }
}

.lh-md {
    line-height: 1.7;

    @media (max-width: $sm-max) {
        line-height: 1.5 !important;
    }
}

.text-initial {
    text-transform: initial;
}

.border-bottom-primary {
    height: 3px !important;
    width: 160px;
    margin: 0 auto;
    color: $color-primary;
    opacity: 1;

    @media (max-width: $sm-max) {
        width: 90px;
    }
}

.border-secondary-light {
    border: 1px solid $color-secondary-light;
}

.font-weight-semibold {
    font-weight: 600;
}

.font-weight-bold {
    font-weight: 700;
}

.card-hover {
    &:hover {
        background-color: $dark-card-bg !important;
    }
}

// Range picker
.mt-lg-35 {
    @media (min-width: $md-min) {
        margin-top: 35px;
    }
}

.form-range::-webkit-slider-runnable-track {
    background-color: #5a5a5a;
    height: 10px;
}

.form-range::-webkit-slider-thumb {
    background-color: $color-primary;
    width: 24px;
    height: 24px;
    margin-top: -9px;
    outline: none !important;
    transition: none !important;
}

.form-range::-webkit-slider-thumb:active {
    background-color: $color-primary;
    outline: none !important;
    transition: none !important;
}

.video-wrapper {
    width: 70%;
    margin: auto;
    position: relative;

    @media (max-width: $sm-min) {
        width: 100%;
    }

    .card-img {
        // border-radius: 15px;

        @media (max-width: $sm-max) {
            border-radius: 0;
        }
    }

    .icon-btn-circle-lg {
        width: 72px;
        height: 72px;

        // Small Width
        @media (max-width: $lg-max) {
            width: 80px;
            height: 80px;
        }

        @media (max-width: $sm-max) {
            width: 60px;
            height: 60px;
        }
    }

    // &::after {
    // 	content: '';
    // 	position: absolute;
    // 	top: 0;
    // 	right: 0;
    // 	bottom: 0;
    // 	left: 0;
    // 	background: transparent linear-gradient(180deg, #3f4a7900 0%, #2f3d7b 55%, #24337c 100%) 0% 0%;
    // 	opacity: 0.74;
    // 	border-radius: 9px;

    // 	@media (max-width: $sm-min) {
    // 		border-radius: 0;
    // 	}
    // }
}

// Bootstrap Switch
.form-switch {
    .form-check-input {
        width: 89px;

        @media (max-width: $sm-min) {
            width: 69px;
        }
    }

    .form-check-input {
        height: 39px;
        background-color: #5a5a5a;
        border: 0;
        // background-image: url('../images/icons/switch-circle.svg') !important;
        background-image: url('../../public/images/icons/switch-circle.svg');
        box-shadow: none;

        @media (max-width: $sm-min) {
            height: 29px;
        }

        &:checked {
            background-image: url('../../public/images/icons/switch-circle.svg');
        }
    }
}

// Radio Buttons
[type='radio']:checked,
[type='radio']:not(:checked) {
    position: absolute;
    left: -9999px;
}

[type='radio']:checked+label,
[type='radio']:not(:checked)+label {
    position: relative;
    cursor: pointer;
    height: 70px;
    display: inline-block;
    color: #666;
}

[type='radio']~label {
    span {
        position: relative;
        top: 40px;
        left: 0;
        font-size: 14px;
    }
}

[type='radio']:checked+label:before,
[type='radio']:not(:checked)+label:before {
    content: '';
    position: absolute;
    // left: 0;
    top: 0;
    width: 41px;
    height: 41px;
    border: 4px solid #696969;
    border-radius: 100%;
    background: $white;
}

[type='radio']:checked+label:after,
[type='radio']:not(:checked)+label:after {
    content: '';
    width: 25px;
    height: 25px;
    background: $color-primary;
    position: absolute;
    top: 8px;
    // left: 8px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

[type='radio']:not(:checked)+label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

[type='radio']:checked+label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

// Calander
.react-datepicker {
    border: 0 !important;
    border-radius: 5px 5px 0 0 !important;
    overflow: hidden !important;
}

.react-datepicker__day--outside-month {
    color: #aaaaaa !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    border-radius: 0.3rem;
    background-color: $color-primary !important;
    color: #fff;
}

.react-datepicker__month {
    margin: 0 !important;
}

// .react-datepicker__day{
// 	background: #F8F8F8 !important;
// }
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
    background-color: $color-primary !important;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
    background: #f8f8f8;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    border-radius: 0.3rem;
    background-color: $color-primary !important;
    color: #fff;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    width: 82px !important;
    height: 82px !important;
    line-height: 86px !important;
    margin: 0 !important;

    @media (max-width: $sm-max) {
        width: 52px !important;
        height: 52px !important;
        line-height: 52px !important;
    }

    @media (max-width: $xxs-max) {
        width: 42px !important;
        height: 42px !important;
        line-height: 42px !important;
    }
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    font-size: 31px;
    font-family: 'Poppins' !important;

    @media (max-width: $sm-max) {
        font-size: 21px;
    }

    @media (max-width: $xxs-max) {
        font-size: 16px !important;
    }
}

.react-datepicker__day-name {
    font-family: 'Poppins' !important;

    font-size: 24px;
    font-weight: 400 !important;
    background-color: #ebebeb;

    @media (max-width: $sm-max) {
        font-size: 21px;
    }
}

.react-datepicker__header {
    font-family: 'Poppins' !important;

    padding: 0 !important;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    font-family: 'Poppins' !important;

    color: #fff !important;
    background-color: $color-primary;
    font-size: 21px !important;
    padding: 16px 0;
    letter-spacing: 1px;
    font-weight: 500 !important;

    @media (max-width: $sm-max) {
        font-size: 21px !important;
        padding: 12px 0 !important;
    }

    @media (max-width: $xxs-max) {
        font-size: 16px !important;
    }
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
    border-color: #fff !important;
    height: 12px !important;
    width: 12px !important;
}

.react-datepicker__navigation {
    top: 23px !important;

    @media (max-width: $sm-max) {
        top: 22px !important;
    }
}

.react-datepicker__navigation--previous {
    left: 18px !important;
}

.react-datepicker__navigation--next {
    right: 18px !important;

    @media (max-width: $xxs-max) {
        top: 16px !important;
    }
}

.react-datepicker__day--outside-month {
    pointer-events: none;
    color: #cccccc;
}

.react-datepicker__day--disabled {
    pointer-events: none;
    color: #cccccc !important;
}


.build-plane-wrap {
    font-family: 'Poppins' !important;

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--selected {
        background-color: transparent !important;
        color: #0d0d0d;
    }
}

.next-btn {
    width: 280px;
    font-size: 16px;
    padding: 20px 15px;
    font-weight: 600;
    letter-spacing: 1px;

    @media (max-width: $xxs-max) {
        top: 16px !important;
        padding: 15px;
    }
}

.mw-lg {
    max-width: 1080px;

    @media (max-width: $sm-max) {
        max-width: 100%;
    }
}

// Build Steps
.left-text {
    @media (max-width: $sm-max) {
        min-width: 110px;
        text-align: center;
    }
}

.build-plane-wrap {
    background-color: $white;
    border-radius: 15px;
    padding: 25px;
    z-index: 1;

    @media (max-width: $sm-max) {
        padding: 0;
        background-color: transparent;
    }
}

// Get Started
.get-started-training-plan {
    .ls-0 {
        letter-spacing: 0px !important;
        // display: block;
    }

    .heading-meta-data {
        top: 20px;
        left: 20px;
        z-index: 1;
        position: absolute;
        text-transform: uppercase;
        padding: 0.375rem 1.5rem;
        background: linear-gradient(0deg, #742F4E, #A51254);
        border-radius: 50rem;

    }

    .banner-info {
        position: absolute;
        bottom: 10px;
        left: 25px;
        z-index: 1;

        @media (max-width: $lg-max) {
            bottom: 30px;
            left: 30px;
        }

        @media (max-width: $sm-max) {
            // display: none;
            bottom: 10px;
            left: 20px;
        }
    }

    .responsive-card-lg {
        width: 100%;
    }

    .next-btn {
        @media (max-width: $sm-min) {
            width: 100%;
        }
    }
}

.get-started-training-plan {
    .accordion {
        .accordion-item {
            border: 0;
            background-color: transparent;
        }

        .accordion-header {
            background: #fdfdfd;
            position: relative;

            .man-run-icon {
                position: absolute;
                top: 23px;
                left: 13px;
                width: 70px;

                @media (max-width: $sm-max) {
                    left: 3px;
                    width: 60px;
                }

                img {
                    max-width: 100%;
                }
            }
        }

        .accordion-button:not(.collapsed) {
            box-shadow: none;
        }

        .accordion-button {
            background: #fdfdfd;
            margin-bottom: 12px;
            font-size: 15px;
            box-shadow: none;
            padding: 30px 20px 30px 120px;
            font-weight: 400;
            line-height: 1.5;

            @media (max-width: $sm-min) {
                padding: 30px 20px 30px 70px;
            }
        }
    }

    .accordion-button:not(.collapsed) {
        color: #1b1c20;

        &::after {
            transform: rotate(0);
        }
    }

    .accordion-body {
        // font-size: 15px;
        padding-left: 124px;

        @media (max-width: $sm-min) {
            padding-left: 15px;
        }
    }
}

.banner-top-icon {
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 1;
}

.accordion-button {
    &::after {
        background-image: url(../../public/images/icons/download.svg) !important;
        width: 45px;
        height: 45px;
        background-size: 45px;
        transform: rotate(-90deg);
    }
}

.what-day {

    [type='radio']:checked+label:before,
    [type='radio']:not(:checked)+label:before {
        left: 7px;
    }

    [type='radio']:checked+label:after,
    [type='radio']:not(:checked)+label:after {
        left: 15px;
    }
}

.build-plan-wrap {
    @media (max-width: $sm-min) {
        margin-top: 30px;
    }


    // .desiredFinishTime {
    // 	[type='radio']:checked + label:before,
    // 	[type='radio']:not(:checked) + label:before {
    // 		left: 48px;

    // 		@media (max-width: $sm-min) {
    // 			left: calc(50% - 17px);
    // 		}

    // 		@media (max-width: $md-min) {
    // 			left: calc(50% - 15px);
    // 		}

    // 		@media (max-width: $sm-max) {
    // 			left: calc(50% - 17px);
    // 		}
    // 	}
    // }

    // .desiredFinishTime {
    // 	[type='radio']:checked + label:after,
    // 	[type='radio']:not(:checked) + label:after {
    // 		left: 56px;
    // 	}
    // }

    // .fivekTime {
    // 	[type='radio']:checked + label:before,
    // 	[type='radio']:not(:checked) + label:before {
    // 		left: 8px;

    // 		@media (max-width: $sm-min) {
    // 			left: 5px;
    // 		}

    // 		@media (max-width: $md-min) {
    // 			left: 8px;
    // 		}

    // 		@media (max-width: $sm-max) {
    // 			left: 5px;
    // 		}
    // 	}
    // }

    // .fivekTime {
    // 	[type='radio']:checked + label:after,
    // 	[type='radio']:not(:checked) + label:after {
    // 		left: 16px;
    // 	}
    // }

    // .halfMarathonTime {
    // 	[type='radio']:checked + label:before,
    // 	[type='radio']:not(:checked) + label:before {
    // 		left: 8px;

    // 		@media (max-width: $sm-min) {
    // 			left: 5px;
    // 		}

    // 		@media (max-width: $md-min) {
    // 			left: 8px;
    // 		}

    // 		@media (max-width: $sm-max) {
    // 			left: 5px;
    // 		}
    // 	}
    // }

    // .halfMarathonTime {
    // 	[type='radio']:checked + label:after,
    // 	[type='radio']:not(:checked) + label:after {
    // 		left: 16px;
    // 	}
    // }
}

.pointer {
    cursor: pointer;
}

.bg-gray {
    background-color: #5a5a5a;
}

// Your Training Plan
.week-select {
    position: relative;

    &::after,
    &::before {
        z-index: -1;
        content: '';
        background-color: #5a5a5a;
        left: -1000%;
        top: 0;
        bottom: 0;
        position: absolute;
        height: 120px;
        right: 0;

        @media (max-width: $sm-max) {
            height: 70px;
        }
    }

    &::after {
        right: -1000%;
        left: 0;
    }

    .scrollbar-container {
        width: 83%;

        @media (max-width: $lg-max) {
            width: 100%;
        }
    }

    ul {
        li {
            font-size: 16px;
            text-align: center;
            color: $white;
            width: 80px;
            height: 80px;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            flex-shrink: 0;

            @media (max-width: $sm-max) {
                width: 52px;
                height: 52px;
                font-size: 12px;
            }

            span {
                font-size: 36px;
                display: block;
                line-height: 1.1;
                font-style: italic;

                @media (max-width: $sm-max) {
                    font-size: 22px;
                }
            }

            &.active,
            &:hover {
                border: 2px solid $color-primary;
                border-radius: 100%;
            }
        }
    }
}

// Tabs
.nav-tabs {
    border-bottom: 0;
    margin-bottom: 80px;

    .nav-link {
        border: 0;
        font-size: 20px;
        color: #1a1a12;
        font-weight: $h1-weight;
        padding: 5px 16px;
        background-color: #d9d9d9;
        border-radius: 50px;
        margin: 0 12px;

        &.active {
            background-color: $color-primary;
            color: #1a1a12;
        }
    }
}

.font-weight-normal {
    font-weight: $h4-weight;
}

.margin-info {
    @media (max-width: $sm-min) {
        padding-top: 15px !important;
    }
}

.your-traning-plan {

    p,
    div,
    span,
    div.form-control,
    input.form-control,
    select.form-select {
        font-family: $poppins;
    }

    margin-top: -130px;


    @media (max-width: $sm-max) {
        margin-top: -150px;
    }

    @media (min-width: $sm-min) {
        margin-top: -150px;
    }

    @media (min-width: $md-min) {
        margin-top: -80px;
    }

    @media (min-width: $lg-min) {
        margin-top: -120px;
    }

    .flex-column {
        @media (max-width: $sm-max) {
            width: 120%;
            margin-left: -10%;
        }
    }

    .week-tabs {
        &.nav-tabs {
            .nav-link {
                width: 129px;

                @media (max-width: $xxl-middle) {
                    width: 105px;
                }

                @media (max-width: $xxl-min) {
                    width: 88px;
                }

                @media (max-width: $lg-max) {
                    width: 103px;
                }

                @media (max-width: $sm-max) {
                    font-size: 16px;
                    width: 100px;
                }
            }
        }

        &.nav {
            @media (max-width: $lg-max) {
                flex-wrap: nowrap;
                overflow-x: scroll;
                padding-bottom: 10px;
            }
        }
    }

    .nav-pills {
        display: flex;
        flex-direction: row !important;
        flex-wrap: nowrap;
        margin-bottom: 40px;

        .nav-item {
            background-color: transparent;
        }

        .nav-link {
            border: 0;
            // font-size: 20px;
            font-size: 1rem;
            color: #1a1a12;
            font-weight: $h1-weight;
            padding: 5px 16px;
            background-color: #d9d9d9;
            border-radius: 50px;
            margin: 0 12px;
            width: 129px;

            @media (max-width: $xxl-middle) {
                width: 105px;
            }

            @media (max-width: $xxl-min) {
                width: 103px;
            }

            @media (max-width: $sm-max) {
                font-size: 16px;
                width: 100px;
            }
        }
    }

    .nav-pills {
        border-bottom: 0;

        .nav-item {
            border: 0;
            font-size: 20px;
            color: #1a1a12;
            font-weight: $h1-weight;
            padding: 0;
            margin: 0;
            // background-color: #d9d9d9;
            border-radius: 50px;
            text-align: center;
            cursor: pointer;

            &.active {
                background-color: $color-primary;
                color: #1a1a12;
            }
        }

        .nav-link {
            &.active {
                background-color: $color-primary;
            }
        }
    }

    .nav {
        @media (max-width: $xxl-min) {
            flex-wrap: nowrap;
            overflow-x: scroll;
            padding-bottom: 10px;
        }
    }
}


.traning-type {
    position: absolute;
    top: 60px;

    li {
        margin: 0 21px;

        @media (max-width: $xxl-middle) {
            margin: 0 9px;
        }

        @media (max-width: $xxl-min) {
            margin: 0 25px;
        }

        @media (max-width: $lg-max) {
            margin: 0 15px;
        }

        @media (max-width: $sm-max) {
            width: 110px;
            text-align: center;
            font-size: 11px;
        }
    }
}

.miles-info {
    width: 170px;
    height: 170px;
    background-color: $color-primary;
    border-radius: 100%;

    @media (max-width: $sm-max) {
        max-width: 113px;
        width: 100%;
        height: 113px;
    }

    .font-80 {
        line-height: 100%;

        @media (max-width: $sm-max) {
            font-size: 60px;
        }
    }

    .center-align {
        img {
            @media (max-width: $sm-max) {
                width: 70%;
            }
        }
    }
}

.info-edit-wrap {
    @media (max-width: $sm-max) {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
    }

    .center-align {
        @media (max-width: $sm-max) {
            margin: 0 !important;
        }
    }

    .hr-rate {
        @media (max-width: $sm-max) {
            margin-left: 30px;
        }

        .text-black {
            @media (max-width: $sm-max) {
                font-size: 22px;
            }
        }
    }
}

.device-reverse-content {
    @media (max-width: $sm-max) {
        display: flex;
        flex-direction: column-reverse;
    }
}

.center-align {
    display: flex;
    justify-content: center;
    align-items: center;
}

.center-align--col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h4 {
        font-size: large;
        font-weight: bolder;
    }

    p {
        text-align: center;
    }
}

.popover {
    max-width: 208px;
    border: 1px solid $color-primary;
}

.popover-body {
    padding: 10px;
}

.popover-custom {
    background-color: $white;
    border-radius: 4px;

    .form-control {
        height: 25px;
        width: calc(100% - 55px);
        margin-top: 8px;
        box-shadow: none !important;
    }

    .btn-close {
        position: absolute;
        bottom: 13px;
        right: 15px;
        font-size: 18px;
        box-shadow: none;
    }

    .box-title {
        justify-content: center;
        font-weight: 600;
        font-size: 11px;
        border: none;
        color: #1b1c20;
        padding-bottom: 0;
    }

    .thickmark-icon {
        position: absolute;
        right: 36px;
        bottom: 16px;
        width: 22px;
        height: 22px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.popover-close {
    position: absolute;
    top: 31px;
    right: 4px;
    font-size: 12px;

    img {
        width: 80%;
    }
}

.weather-alert {
    p {
        font-size: 12px !important;
    }

    @media (max-width: $sm-max) {
        display: inline-block;
        background-color: $white;
        padding: 8px;

        .text-orange {
            font-size: 15px !important;
        }
    }

    .popover {
        max-width: 424px;
        padding: 40px 20px 40px;
    }

    .popover-body {
        max-width: 424px;
        text-align: center;
    }

    .w-24 {
        width: 24px;
    }

    .h-24 {
        height: 24px;
    }

    .text-orange {
        color: #ff7600;
    }

    .popover-close {
        top: 15px;
        right: 15px;
    }
}

.save-btn {
    font-size: 14px;
    width: 175px;

    @media (max-width: $sm-max) {
        width: 125px;
    }
}

.font-12 {
    font-size: 12px;
}

.info-wrap {
    .popover {
        max-width: 373px;
    }

    .btn-close {
        top: 0;
        color: $color-primary;
        font-size: 14px;
    }

    .font-12 {
        font-size: 10px;
    }

    .popover-close {
        top: 5px;

        img {
            width: 60%;
        }
    }
}

.notes-box {
    position: relative;

    textarea {
        background: #f8f7f7 !important;
        height: 173px;
        resize: none;
        padding: 17px 26px;
        font-size: 13px;

        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 0.5rem !important;

        @media (max-width: $sm-max) {
            height: 200px;
            background: $white !important;
            padding: 17px 26px 60px 26px;
        }

        @media (max-width: $md-max) {
            margin-top: 25px;
        }

        &:focus {
            box-shadow: none;
        }
    }

    .traning-save-btn {
        @media (max-width: $sm-min) {
            position: absolute;
            bottom: 65px;
            left: 0;
            right: 0;
            text-align: center;
        }
    }
}

.workout-img {
    width: 120px;
    height: auto;
    overflow: hidden;
    object-fit: cover;
    border-radius: 5px;
    position: relative;

    .like-option {
        img {
            width: 30px !important;
        }

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #0d0d0d !important;
    font-weight: $h1-weight !important;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: #0d0d0d !important;
    font-weight: $h1-weight !important;
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: #0d0d0d !important;
    font-weight: $h1-weight !important;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: #0d0d0d !important;
    font-weight: $h1-weight !important;
}

.w-12 {
    width: 12px;
}

.workout-play-icon {
    img {
        width: 40px;
        height: 40px;
    }
}

.workout-video-inner {
    border: 1px solid #aaaaaa;
    padding: 15px;
    border-radius: 5px;

    @media (max-width: $sm-max) {
        border: 0;
        background-color: #ffffff;
    }
}

.bg-primary {
    background-color: $color-primary !important;
}

.bg-tertiary {
    background-color: $color-tertiary !important;
}

.bg-pink {
    background-color: #cd2b6e !important;
}

.common-modal {
    .modal-header {
        display: block;
        text-align: center;
        padding: 40px 16px 0 16px;
        border: 0;

        .btn-close {
            position: absolute;
            top: 15px;
            right: 20px;
            font-size: 22px;
        }
    }

    .modal-footer {
        border: 0;
        justify-content: center;
        padding: 12px 6px 45px 12px;
    }
}

.confirm-change-plan {

    .modal-content {
        border-radius: 10px;
    }

    .modal-header {
        display: block;
        text-align: center;
        padding: 40px 16px 0 16px;
        border: 0;

        .btn-close {
            position: absolute;
            top: 15px;
            right: 20px;
            font-size: 22px;
        }
    }
}

.change-plan {

    .modal-content {
        border-radius: 10px;
    }

    ul {
        li {
            background: #f8f7f7;

            @media (max-width: $sm-max) {
                background: $white;
                padding: 17px 12px !important;
            }
        }
    }

    .save-btn {
        &:hover {
            background-color: #000000;
        }
    }
}

//  Modal datepicker
.modal-datepicker {

    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
        font-size: 21px !important;
        padding: 9px 0;
    }

    .react-datepicker__current-month,
    .react-datepicker__day-name {
        font-size: 24px !important;

        @media (max-width: $sm-max) {
            font-size: 21px !important;
        }
    }

    .react-datepicker__day-name {
        font-size: 24px;
        display: table-cell;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        width: 60px !important;
        height: 60px !important;
        line-height: 60px !important;
        margin: 0 !important;

        @media (max-width: $xxs-max) {
            width: 49px !important;
        }

        @media (max-width: 380px) {
            width: 41px !important;
            height: 41px !important;
        }
    }

    .date-wrap {
        font-size: 11px;
        vertical-align: top;
        text-align: right !important;
        display: block;
        line-height: 1;
        padding-top: 3px;
        padding-right: 3px;

        @media (max-width: $sm-max) {
            font-size: 9px;
        }
    }

    .date-text-wrap {
        font-size: 18px;
        text-align: center;
    }

    .highlated-date {
        font-size: 20px;
        font-weight: 600;
        font-style: italic;
        display: block;
        height: auto;
        line-height: 0.8;

        @media (max-width: $sm-max) {
            margin-top: 8px;
        }

        @media (max-width: 380px) {
            margin: 0;
            font-size: 16px;
        }
    }

    .base {
        font-size: 9px;
        font-weight: 500;
        background: $color-primary;
        display: block;
        color: $white;
        width: 100%;
        line-height: initial;
        margin-top: 16px;

        @media (max-width: $sm-max) {
            font-size: 8px;
            margin-top: 12px;
        }

        @media (max-width: 380px) {
            font-size: 6px;
            margin-top: 6px;
        }
    }

    .react-datepicker__day,
    .react-datepicker__month-text,
    .react-datepicker__quarter-text,
    .react-datepicker__year-text {
        font-size: 11px;
    }

    .react-datepicker__day,
    .react-datepicker__month-text,
    .react-datepicker__quarter-text,
    .react-datepicker__year-text {
        border-right: 0.5px solid #cccccc;
        border-bottom: 0.5px solid #cccccc;
        border-radius: 0 !important;
        vertical-align: top;
        color: $black !important;
        background-color: $white !important;
    }

    .react-datepicker__month {
        border-left: 0.5px solid #cccccc;
        margin-top: 7px !important;
    }

    .cross-text {
        display: block;
        line-height: 1.2;
        font-size: 12px;
        font-family: $poppins;
        font-weight: 500;

        @media (max-width: $xxs-max) {
            display: inherit;
        }

        @media (max-width: $sm-max) {
            font-size: 12px;
            display: block;
            margin: 5px 0 0;
        }

        @media (max-width: 380px) {
            font-size: 8px;
            margin: 0;
        }
    }

    .react-datepicker__navigation {
        top: 19px !important;
    }

    .react-datepicker__day--outside-month {
        color: #aaaaaa !important;
        pointer-events: none;
    }
}

.view-calandar {
    .btn-dark {
        min-width: 125px;
    }
}

.font-11 {
    font-size: 11px;
}

.traning-plan-dropdown {
    .btn-outline-primary {
        border-color: #707070;
        color: $black;
        background-color: transparent !important;
        border-radius: 10px;
        margin-top: 20px;
        font-size: 14px;
        padding: 13px 12px;

        @media (max-width: $sm-max) {
            position: fixed;
            top: 42px;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 225px;
        }

        @media (max-width: $sm-max) {
            background-color: $white !important;
            color: #6f6f6f;
            font-weight: 600;
        }
    }

    .dropdown-toggle {
        &::after {
            border-top: 10px solid #707071;
            border-right: 10px solid transparent;
            border-bottom: 0;
            border-left: 10px solid transparent;
            position: relative;
            top: 3px;
        }
    }

    .dropdown-menu {
        border: 1px solid #707070;
        border-radius: 10px;
        width: 198px;

        @media (max-width: $sm-max) {
            background-color: #f6f5f5;
            margin-top: -9px;
            border-top: 0 !important;
            border-top-left-radius: 0 !important;
            border-top-right-radius: 0 !important;
            width: 225px;
        }
    }

    .dropdown-item {
        font-size: 14px;
        color: #716f6f !important;

        @media (max-width: $sm-max) {
            padding: 9px 13px;
        }

        &:hover {
            font-weight: 700;
            background-color: transparent;
        }

        &.active,
        &:focus {
            background-color: transparent;
        }
    }
}

.modal-content {
    border-radius: 10px;
}

.font-30 {
    font-size: 30px;
}

.edit-locations-modal {
    ::-webkit-input-placeholder {
        font-size: 0.8rem !important;
        font-weight: 300 !important;
        font-family: 'Poppins' !important;
        color: #707070 !important;
    }

    .form-control {
        height: 60px;
        border-radius: 10px;
        background-color: #f8f7f7;
        box-shadow: none;
        padding: 6px 49px 6px 12px;
    }

    .edit-loations-pin {
        position: absolute;
        top: 15px;
        right: 20px;
    }
}

.bg-md-white {
    @media (min-width: $md-min) {
        background-color: $white !important;
    }
}

.edit-options-list {
    @media (max-width: $sm-max) {
        li {
            background-color: $white !important;
            padding: 20px;
            border-radius: 4px;
            width: 100%;
        }

        h5 {
            font-size: 16px !important;
        }
    }
}

.sidebar-open-btn {
    position: fixed;
    bottom: 110px;
    right: 30px;
    width: 30px;
    height: 30px;

    img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
}

.offcanvas {
    background: #f6f5f5;
}

.offcanvas-header {
    justify-content: flex-end;

    .btn-close {
        // background-image: url(../../public/images/icons/close-button.svg) !important;
        width: 10px;
        height: 10px;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 50% !important;
        margin-right: 20px;
        margin-top: 10px;

        @media (max-width: $xxs-max) {
            margin: 0;
        }
    }
}

.week-sum-list {
    @media (max-width: $sm-max) {
        background: $white;
        display: table;
        margin: 0 auto;
        padding: 5px 22px;
        border-radius: 50px;
    }
}

@media (max-width: $sm-max) {
    .font-md-16 {
        font-size: 16px;
    }

    .offcanvas-start {
        width: 100%;
    }
}

.common-modal {
    .btn-close {
        @media (max-width: $sm-max) {
            // background-image: url(../../public/images/icons/close-button.svg) !important;
            width: 10px;
            height: 10px;
            background-repeat: no-repeat;
            background-size: cover;
            opacity: 50% !important;
            margin-right: 20px;
            margin-top: 10px;

            @media (max-width: $xxs-max) {
                margin: 0;
            }
        }
    }

    @media (max-width: $sm-max) {
        .modal-dialog {
            margin: 0;
            max-width: 100% !important;
        }

        .modal-header {
            border: 0;
        }

        .modal-content {
            background-color: #f3f2f2;
            height: 100%;
            border: 0;
            border-radius: 0;
        }

        .modal-dialog {
            height: 100%;
        }

        .bg-mobile-green {
            background-color: $color-primary !important;
            border-color: $color-primary !important;
        }
    }
}

@media (max-width: $sm-max) {
    .offcanvas-body {
        padding: 40px 14px 14px 14px;
    }

    .modal-backdrop {
        &.show {
            opacity: 1;
            border: 0;
            background-color: #f3f2f2;
        }
    }

    .font-weight-md-semibold {
        font-weight: $h1-weight;
    }

    .change-plan ul {
        li {

            .text-uppercase,
            .text-primary {
                font-size: 16px;
                font-weight: 600;
            }
        }
    }

    .edit-locations-modal {
        h5 {
            font-size: 16px;
        }
    }

    .adjust-race-modal {
        h2 {
            // text-transform: uppercase !important;
            font-size: 24px;
        }
    }

    .font-md-14 {
        font-size: 14px;
    }

    .goal-races-device {
        .row {
            flex-wrap: nowrap;
            overflow-x: scroll;

            >* {
                width: 85%;
            }
        }
    }
}

.desktop-plan-comp {
    .remove-devices {
        @media (max-width: $sm-max) {
            display: none;
        }
    }
}

// Please wait
.please-wait-wrap {
    width: 604px;
    margin: 0 auto;
    position: relative;
    padding-top: 170px;

    @media (max-width: $sm-max) {
        width: 345px;
    }
}

.loader-bar {
    background-color: #f5f5f5;
    border-radius: 33px;
    height: 34px;
    border: 3px solid #ebebeb;
    position: relative;
    overflow: hidden;

    .loader-fill {
        width: 27px;
        height: 27px;
        background-color: $color-primary;
        border-radius: 33px;
        display: inline-block;
    }

    &.active {
        .loader-fill {
            width: 572px;
            border-radius: 33px;
            transition: 4s;

            @media (max-width: $sm-max) {
                transition: 22s;
            }
        }
    }
}

.loader-wrap {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -70%);
    left: 50%;

    .font-21 {
        @media (max-width: $sm-max) {
            font-size: 16px;
        }
    }
}

.loader-img {
    position: absolute;
    left: 0;
    bottom: 125px;
    top: auto;
    background-size: 100%;
    height: 87px;
    width: 87px;
    background-image: url(../../public/images/icons/loader-logo.png) !important;
    background-repeat: no-repeat;

    &.active {
        transition: 4s;
        transform-origin: 0 0;
        animation: leaves 4s ease-in-out;
        transform: scale(1.5);
        left: 230px;

        @media (max-width: $sm-max) {
            transition: 10s;
            animation: leaves 10s ease-in-out;
            left: 200px;
        }
    }
}

.font-21 {
    font-size: 21px;
}

@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }

    @-moz-keyframes #{$name} {
        @content;
    }

    @-ms-keyframes #{$name} {
        @content;
    }

    @keyframes #{$name} {
        @content;
    }
}

@include keyframes(leaves) {
    0% {
        transform: scale(1);
    }

    10% {
        transform: scale(1.1);
    }

    20% {
        transform: scale(1.2);
    }

    30% {
        transform: scale(1.3);
    }

    40% {
        transform: scale(1.4);
    }

    50% {
        transform: scale(1.5);
    }
}

.mobile-menu-title {
    font-size: 0.8rem;
    line-height: 1.6;
    font-weight: 500;
    text-transform: uppercase;
    font-family: 'Josefin Sans', sans-serif;
}

.mobile-menu-icon {

    svg,
    img {
        width: 35px;
        height: 35px;
    }
}

.user-info-page--wrapper {
    display: flex;
    flex-wrap: wrap;

    .user-login--title,
    .user-step--title {
        flex: 1 0 100%;
        max-width: 100%;

        @media (max-width: $md-min) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    .user-login--title {
        text-align: left;
        text-transform: uppercase;

        h3 {
            overflow: visible;
            white-space: nowrap;
            text-align: center;
            font-family: $overpass !important;
            font-style: normal;
            font-weight: bold;
            font-size: 2rem;
            color: rgba(26, 26, 18, 1);
            letter-spacing: -0.1px;
            text-transform: none;
        }

        @media (max-width: $md-min) {
            margin: 0 auto;

            h3 {
                white-space: unset;
            }

            text-align: center;

        }
    }

    .user-step--title {
        text-align: right;

        @media (max-width: $md-min) {
            display: none;
            text-align: center;
        }
    }

    .user--text {
        flex: 0 0 100%;
        max-width: 100%;
        justify-content: center;
    }

    .choose-plan--text {
        @media (max-width: $sm-min) {
            text-align: center;
        }
    }
}

.signup-container-sm {
    background-color: #ffffff;
    padding: 40px;

    @media (min-width: $lg-min) {
        max-width: 700px;
    }

    @media (max-width: $sm-min) {
        margin-top: 40px;
        padding: 30px;
        position: absolute;
        bottom: 0;
        left: 0;
        top: 0;
    }

    div.form-control,
    input.form-control,
    select.form-select {
        font-family: $overpass;
        font-size: $content-font-size;
        line-height: $content-line-height;
    }

    .sm-text-center {
        @media (max-width: $sm-min) {
            text-align: center;
        }
    }
}

.signup-container {
    background-color: #ffffff;
    padding: 40px;

    @media (min-width: $lg-min) {
        max-width: 800px;
    }

    div.form-control,
    input.form-control,
    select.form-select {
        font-family: $overpass;
        font-size: $content-font-size;
        line-height: $content-line-height;
    }

    @media (max-width: $sm-min) {
        margin-top: 10px;
        padding: 30px;
        position: absolute;
        bottom: 0;
        left: 0;
        top: 0;
    }

    .sm-text-center {
        @media (max-width: $sm-min) {
            text-align: center;
        }
    }
}

.account-container-small {
    border-radius: 10px;

    background-color: #ffffff;
    margin: 0 auto;

    @media (min-width: $lg-min) {
        max-width: 700px;
    }

    .sm-text-center {
        @media (max-width: $sm-min) {
            text-align: center;
        }
    }
}

.account-container {
    .eq-with-pill {
        width: 120px;
    }

    .renew--title {
        flex: 1 0 50%;
        max-width: 50%;

        @media (max-width: $md-min) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    .renew--title {
        text-align: left;
        text-transform: uppercase;

        h3 {
            font-weight: 700 !important;
            font-family: $poppins-bold;
        }

        @media (max-width: $md-min) {
            text-align: center;
        }
    }

    border-radius: 10px;

    background-color: #ffffff;
    margin: 100px auto;

    @media (min-width: $lg-min) {
        max-width: 1000px;
    }

    @media (max-width: $sm-min) {
        margin: 64px 10px 0px 10px;
        // padding: 30px;
        // position: absolute;
    }

    .sm-text-center {
        @media (max-width: $sm-min) {
            text-align: center;
        }
    }
}

.sm-normal-text {
    @media (max-width: $sm-max) {
        font-family: $poppins-medium !important;
    }
}

.c-checkbox--wrapper {
    .form-check-input {
        &:checked {
            ~div {
                div {
                    .custom-checkbox {
                        &:after {
                            transform: scale(1);
                        }
                    }
                }
            }
        }
    }

    .detail-text {
        margin-left: 35px;
    }

    .custom-checkbox {
        padding-left: 35px;
        position: relative;

        &::before {
            position: absolute;
            content: '';
            width: 25px;
            height: 25px;
            border-radius: 50%;
            border: 1px solid #9fcd2b;
            top: 0;
            left: 0;
        }

        &:after {
            position: absolute;
            content: '';
            width: 13px;
            height: 13px;
            border-radius: 50%;
            background-color: #9fcd2b;
            top: 6px;
            left: 6px;
            transform: scale(0);
        }
    }
}

.welcome-page--wrapper {
    .account-form {

        form {
            .row {
                #first-input {
                    .input-group {
                        width: 100%;
                    }
                }
            }

            .col-9 {
                .input-group {
                    width: 46%;
                }
            }

            @media (max-width: $sm-min) {

                #fav-distance-input,
                #join-reason-input {
                    .input-group {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.distance--title {
    line-height: 1;
}

.race-distance--text {
    font-weight: 500;

    @media (min-width: $sm-min) {
        br {
            display: none;
        }
    }
}

.build-plane-wrap {
    .react-datepicker__month-container {
        .react-datepicker__week {
            .react-datepicker__day {
                background-color: #ffffff;
                font-size: 21px;
                font-weight: 400;

                @media (max-width: $sm-min) {
                    font-size: 14px;
                }

                &:hover {
                    background-color: #9fcd2b !important;
                    color: #ffffff !important;
                }
            }
        }
    }
}

.build-plane-stepthree {
    .plan--text {
        color: #5a5a5a;
        font-size: 0.9rem;
        font-weight: 400;

        @media (max-width: $sm-min) {
            font-size: 0.8rem;
        }
    }

    // Radio Buttons
    [type='radio']:checked,
    [type='radio']:not(:checked) {
        display: none;
    }

    [type='radio']:checked+label,
    [type='radio']:not(:checked)+label {
        position: relative;
        cursor: pointer;
        min-height: auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        // margin-bottom: 55px;
        // overflow-y: hidden;
    }

    [type='radio']~label {
        span {
            position: relative;
            top: 45px;
            // left: 0;
            font-size: 14px;
            // white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: center;
        }
    }

    [type='radio']:checked+label:before,
    [type='radio']:not(:checked)+label:before {
        content: '';
        position: absolute;
        width: 41px;
        height: 41px;
        min-height: 41px;
        border: 4px solid #696969;
        border-radius: 100%;
        background: $white;
    }

    [type='radio']:checked+label:after,
    [type='radio']:not(:checked)+label:after {
        content: '';
        width: 25px;
        height: 25px;
        background: $color-primary;
        position: absolute;
        top: 8px;
        // left: 8px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }

    [type='radio']:not(:checked)+label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    [type='radio']:checked+label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.traning-plan--text {
    font-family: $poppins;
    font-size: 0.9rem;
    line-height: 1.7;
}

.like-image-container {
    z-index: 9999 !important;
}

.video-wrapper-details {
    .heart-image {

        img {
            width: 50px !important;
        }

        @media (max-width: $sm-max) {
            img {
                width: 40px !important;
            }
        }
    }

    .tick-mark {
        position: absolute !important;
        bottom: 11px;
        right: 10px;

        img {
            width: 30px !important;
        }

        @media (max-width: $sm-max) {
            img {
                width: 30px !important;
            }
        }
    }
}

.get-started-training-plan {

    .heart-image {
        position: absolute !important;
        bottom: 14px;
        right: 44px;

        img {
            width: 25px !important;
        }

        @media (max-width: $sm-max) {
            img {
                width: 25px !important;
            }
        }
    }

    .tick-mark {
        position: absolute !important;
        bottom: 14px;
        right: 10px;

        img {
            width: 25px !important;
        }

        @media (max-width: $sm-max) {
            img {
                width: 25px !important;
            }
        }
    }

    .responsive-card-lg:not(.playing) {
        .play-btn {
            z-index: 100;
        }

        &::before {
            position: absolute;
            content: '';
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            // background: transparent linear-gradient(180deg, #3f4a7900 0%, #2f3d7b 55%, #24337c 100%) 0% 0% no-repeat padding-box;
            background: linear-gradient(0deg, rgb(3 17 52), rgba(0, 0, 0, 0));
            opacity: 0.74;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;

            @media (max-width: $sm-max) {
                border-radius: 0;
            }

        }
    }
}

.font-weight-200 {
    font-weight: 200;
}

.font-weight-400 {
    font-weight: 400;
}

.font-weight-500 {
    font-weight: 500;
}

.font-weight-700 {
    font-weight: 700;
}

.marathon-value--text {
    font-size: 1.5rem;
    font-family: 'Roboto', sans-serif;
    font-style: italic;
}

.weather-traning--icon {
    width: 20px;
}

.weather-custom--popup {
    display: flex;

    .form-control {
        width: 100%;
        height: 40px;
        background: #eeeeee;
        font-weight: 400;

        &::-webkit-input-placeholder {
            font-weight: 400;
            font-size: 12px;
        }
    }
}

.account-form {
    .form-control {
        &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: #545454 !important;
            font-weight: $h6-weight !important;
        }

        &::-moz-placeholder {
            /* Firefox 19+ */
            color: #545454 !important;
            font-weight: $h6-weight !important;
        }

        &:-ms-input-placeholder {
            /* IE 10+ */
            color: #545454 !important;
            font-weight: $h6-weight !important;
        }

        &:-moz-placeholder {
            /* Firefox 18- */
            color: #545454 !important;
            font-weight: $h6-weight !important;
        }
    }
}

.left-icon {
    position: relative;

    &.download-left-icon {
        .back-btn {
            top: 0;
        }
    }

    .back-btn {
        position: absolute;
        left: 0;
        margin: 0;
        top: -13px;

        @media (min-width: $sm-min) {
            display: none;
        }
    }
}

.fav-icon {
    .animation {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        transform: scale(0);
        opacity: 0;

        span {
            width: 4px;
            height: 4px;
            background-color: red;
            border-radius: 100%;
            display: block;
            position: absolute;

            &.dot-1 {
                top: 0;
                left: calc(50% - 4px);
            }

            &.dot-2 {
                right: 0;
                top: calc(50% - 4px);
            }

            &.dot-3 {
                bottom: 0;
                left: calc(50% - 4px);
            }

            &.dot-4 {
                left: 0;
                top: calc(50% - 4px);
            }

            &.dot-5 {
                top: 5px;
                right: 4px;
            }

            &.dot-6 {
                bottom: 5px;
                right: 4px;
            }

            &.dot-7 {
                bottom: 5px;
                left: 4px;
            }

            &.dot-8 {
                top: 5px;
                left: 4px;
            }
        }
    }

    .fill {
        display: none;
    }

    label {
        position: relative;
    }

    input:checked {
        &~label {
            .animation {
                animation: zoomOut 0.5s ease-in-out;
            }

            .fill {
                margin-bottom: -6px;
                display: block;
            }

            .without-fill {
                display: none;
            }
        }
    }
}

.center-content {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@keyframes zoomOut {
    0% {
        transform: scale(0);
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        transform: scale(2);
        opacity: 0;
    }
}

.weather-custom--popup {
    .edit-locations-modal {
        .edit-loations-pin {
            top: 6px;
        }
    }
}

.mark-input--wrapper {
    width: 180px;
    margin: auto;
}

.step-back-btn {
    width: 280px;
    font-size: 16px;
    padding: 20px 15px;
    font-weight: 600;
    letter-spacing: 1px;
    background: #ebebeb;
    border-color: #ebebeb;
    color: #000000;

    @media (max-width: 280px) {
        width: 200px;
    }
}

.traning-page--title {
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;

    @media (min-width: $md-min) {
        font-size: 2rem;
        font-weight: 500;
        letter-spacing: 0px;
    }

    @media (max-width: $md-min) {
        display: none;
    }
}

.cross-train--text {
    @media (max-width: $sm-min) {
        font-size: 16px;
    }
}

.cross-train--desc {
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: 0;
    text-transform: capitalize;

    @media (max-width: $sm-min) {
        font-size: 1rem;
    }
}

.popover-close {
    display: none;
}

.input-box {
    input {
        width: 89px;
        margin: 0 5px;
        height: 43px;
        padding: 0 10px;
        border: 1px solid #d6d3d3;
        font-size: 13px;

        &::-webkit-input-placeholder {
            font-size: 0.8rem !important;
            font-weight: 300 !important;
            font-family: 'Poppins' !important;
            color: #707070 !important;
        }

        &::-moz-placeholder {
            font-size: 0.8rem !important;
            font-weight: 300 !important;
            font-family: 'Poppins' !important;
            color: #707070 !important;
        }

        &:-ms-input-placeholder {
            font-size: 0.8rem !important;
            font-weight: 300 !important;
            font-family: 'Poppins' !important;
            color: #707070 !important;
        }

        &:-moz-placeholder {
            font-size: 0.8rem !important;
            font-weight: 300 !important;
            font-family: 'Poppins' !important;
            color: #707070 !important;
        }
    }
}

.notes-box {
    textarea {
        &::-webkit-input-placeholder {
            font-size: 0.8rem !important;
            font-weight: 300 !important;
            font-family: 'Poppins' !important;
            color: #707070 !important;

            @media (max-width: $sm-max) {
                font-size: 13px !important;
            }
        }

        &::-moz-placeholder {
            font-size: 0.8rem !important;
            font-weight: 300 !important;
            font-family: 'Poppins' !important;
            color: #707070 !important;

            @media (max-width: $sm-max) {
                font-size: 13px !important;
            }
        }

        &:-ms-input-placeholder {
            font-size: 0.8rem !important;
            font-weight: 300 !important;
            font-family: 'Poppins' !important;
            color: #707070 !important;

            @media (max-width: $sm-max) {
                font-size: 13px !important;
            }
        }

        &:-moz-placeholder {
            font-size: 0.8rem !important;
            font-weight: 300 !important;
            font-family: 'Poppins' !important;
            color: #707070 !important;

            @media (max-width: $sm-max) {
                font-size: 13px !important;
            }
        }
    }
}

.traning-popup--title {
    font-size: 30px;
    text-transform: capitalize;
    font-weight: 500;
    letter-spacing: 0;

    @media (max-width: $sm-min) {
        font-size: 24px;
    }
}

.build-plan-wrap {
    .timeType {
        margin: 0 15px 0 15px;
        text-align: center;

        @media (max-width: $sm-max) {
            // flex: 0 0 35%;
            // max-width: 35%;
            margin: 0;
            display: flex;
            justify-content: center;

            .plan--text {
                // margin-left: -20px;
                text-align: center;
                min-width: 100px;
            }
        }
    }

    // .desiredFinishTime {
    // 	margin: 0 30px 0 0;
    // 	@media (max-width: $sm-max) {
    // 		flex: 0 0 35%;
    // 		max-width: 35%;
    // 		margin: 0;
    // 		display: flex;
    // 		justify-content: center;
    // 	}
    // 	&.step-radio-box {
    // 		@media (max-width: $sm-max) {
    // 			[type='radio'] {
    // 				&:checked {
    // 					~ label {
    // 						&::after {
    // 							left: 45px;
    // 						}
    // 					}
    // 				}
    // 			}
    // 		}
    // 	}
    // }

    // .fivekTime {
    // 	@media (max-width: $sm-max) {
    // 		flex: 0 0 37%;
    // 		max-width: 37%;
    // 		display: flex;
    // 		justify-content: center;
    // 	}
    // 	&.step-radio-box {
    // 		@media (max-width: $sm-max) {
    // 			[type='radio'] {
    // 				&:checked {
    // 					~ label {
    // 						&::after {
    // 							left: 13px;
    // 						}
    // 					}
    // 				}
    // 			}
    // 		}
    // 	}
    // }
}

.marathon--info {
    @media (max-width: $sm-max) {
        flex-flow: column;
    }
}

.check-complete {
    @media (max-width: $md-max) {
        margin: 0 0 10px 20px;
    }

    will-change: opacity;

    input:checked {
        ~label {
            .checked-icon {
                will-change: opacity;
                display: flex;

                svg {
                    fill: #ffffff;
                }
            }

            .uncheck-icon {
                will-change: opacity;
                display: none;
            }

            .check-complete-text {
                will-change: opacity;
                color: #000000;
            }
        }
    }

    label {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-left: -16px;

        @media (max-width: $sm-max) {
            margin-left: -2px;
        }

        .check-complete-text {
            color: #1b1c204d;
            margin-left: 5px;

            @media (max-width: $sm-max) {
                text-transform: uppercase;
            }
        }
    }

    .uncheck-icon,
    .checked-icon {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        padding: 5px;
        border: 1px solid #1b1c204d;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 18px;
            height: 18px;
            fill: #1b1c204d;
        }
    }

    .checked-icon {
        display: none;
        background-color: #000000;
        border-color: #000000;

        svg {
            color: #ffffff;
        }
    }
}

.card-img-overlay {
    .check-complete {
        position: absolute;
        bottom: 15px;
        right: 15px;
    }
}

.btn-outlined {
    height: 50px;
    display: flex;
    align-items: center;
    text-align: start;
    font-size: 13px;
    font-family: $poppins;
    color: #1a1a12;
    line-height: 1;

    svg {
        margin-right: 7px;
        color: #1a1a12;

    }
}

.cancel-plan-btn {
    font-family: $poppins-medium;
    font-style: normal !important;
    text-decoration: underline;
}

.cancel-plan-card {
    align-items: center;
    text-align: start;
    @extend .c-checkbox--wrapper;
    padding: 10px;

    .form-group {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: #f77d61;
    }

    .cancel--title {
        text-transform: none;
        font-family: $poppins-bold;
    }

    .text-small {
        div {
            font-family: $poppins-light;
        }

        font-family: $poppins-light;

    }

    .card-img-top {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }

    .card {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }

    .rounded-pill {
        width: 100%;
        height: 50px;
        border-radius: 5px !important;

        @media (max-width: $sm-max) {
            height: 100%;
        }

        background: var(---9fcd2b) 0% 0% no-repeat padding-box;
        background: #9FCD2B 0% 0% no-repeat padding-box;
    }

    .fw-bold {
        font-size: 20px;
    }


    .btn {
        text-transform: uppercase !important;
        font-size: 15px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $poppins-medium;
    }



}

.card-link {

    .check-complete,
    .banner-top-icon {
        z-index: 9;
    }

    .uncheck-icon {
        border: 1px solid #ffffff;

        svg {
            fill: #ffffff;
        }
    }

    .checked-icon {
        background-color: #ffffff;
        border-color: #ffffff;

        svg {
            fill: #000000 !important;
        }
    }
}

.adjust-speed-workout {
    margin-top: 27px;

    @media (max-width: $sm-max) {
        margin-top: 15px;
    }
}

.pace-compress {
    margin-top: -60px !important;

    @media (max-width: $sm-max) {
        margin-top: -50px !important;
    }
}

.pace-compress-walk {
    margin-top: -55px !important;

    @media (max-width: $sm-max) {
        margin-top: -45px !important;
    }
}

.pace-details {
    position: relative;
    cursor: pointer;
    display: inline-block;
    margin: 0px 0px 0px 3px;
    font-weight: normal !important;
}

.pace-main {
    margin-top: -20px;

    @media (max-width: $sm-max) {
        margin-top: 0px !important;
    }
}

.text-overflow-el {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;

    @media (max-width: $sm-max) {
        width: 100%;
    }
}

.fixed-nav {
    // position: fixed;
    // top: 66px;
    z-index: 1;
    width: 100%;
    left: 0;

    // @media (max-width: $sm-max) {
    // 	top: 135px;
    // }

    // @media (max-width: $md-max) {
    // 	top: 135px;
    // }
}

.fixed-nav-days {
    position: fixed;
    top: 186px;
    z-index: 9999;

    @media (max-width: $sm-max) {
        top: 116px;
        left: 0;
        width: 100%;
        margin-bottom: 186px;
    }

    @media (max-width: $md-max) {
        top: 116px;
        width: 100%;
        left: 0;
        margin-bottom: 186px;
    }

}

.hover-link {
    &:hover {
        font-weight: 700 !important;
        color: #1a1a12 !important;
    }
}

.loader-circle {
    border-radius: 40px;
    width: 80px;
    height: 80px;

    @media (max-width: $sm-max) {
        border-radius: 30px;
        width: 60px;
        height: 60px;
    }
}

.plan-start-info {
    position: fixed;
    text-align: center;
    width: 100%;
    left: 0;
    right: 0;
    top: 59px;
    display: block;
    padding-top: 4px;
    padding-bottom: 4px;
    background-color: #E93323;
    color: #fff;
    z-index: 100;

    @media (max-width: $lg-min) {
        top: 60px;
        font-size: 1rem;
    }

    @media (max-width: $md-min) {
        top: 42px;
        font-size: 12px;
    }
}

.no-top {
    top: 0px !important;
}

.training-header {
    @media (max-width: $md-min) {
        margin-top: 85px;
    }
}

.select-week-title {
    @media (max-width: $sm-max) {
        margin-left: -10px;
    }
}

.training-calendar {
    padding: 20px 0px;

    p,
    div,
    span,
    div.form-control,
    input.form-control,
    select.form-select {
        font-family: 'Poppins'
    }

    .react-datepicker__month-container {
        .react-datepicker__week {
            text-align: end;
            display: flex;
            flex-direction: row;

            .blank {
                pointer-events: none;
                margin-top: -999px !important;
            }

            .react-datepicker__day {
                background-color: #fefefe;
                font-weight: 400 !important;
                height: auto !important;
                display: flex;
                flex-direction: column;
                align-items: center;
                align-content: flex-start;
                padding: 0.8em;

                @media (max-width: $sm-max) {
                    font-size: 10px !important;
                    margin-top: 0px !important;
                    margin-bottom: 0px !important;
                }

                &:hover {
                    background-color: #9fcd2b !important;
                    color: #ffffff !important;

                    .date-wrap {
                        color: #ffffff !important;
                    }

                    .date-text-wrap {
                        color: #ffffff !important;
                    }

                    .highlated-date {
                        color: #ffffff !important;
                    }

                    .base {
                        color: #ffffff !important;
                    }
                }

                .date-wrap {
                    text-align: end;
                    color: #9fcd2b;
                    font-weight: 600;
                    font-size: 17px;

                    @media (max-width: $sm-min) {
                        font-size: 12px !important;
                    }
                }

                .date-text-wrap {
                    font-size: 15px;
                    text-align: center;
                    font-weight: 400;

                    .numeric {
                        font-style: italic;
                        font-weight: 400;
                        font-size: 17px;

                        @media (max-width: $sm-min) {
                            font-size: 11px !important;
                        }
                    }

                    .non-numeric {
                        font-size: 15px;
                        font-weight: 400;
                        line-height: 1.2;
                        display: block;

                        @media (max-width: $sm-min) {
                            font-size: 11px !important;
                        }
                    }

                    .base {
                        font-weight: 400;
                    }

                    .highlated-date {
                        @media (max-width: $sm-min) {
                            font-size: 11px !important;
                        }

                    }
                }

            }


            .react-datepicker__currentDay {
                background-color: #9fcd2b !important;
                color: #ffffff !important;
                border-radius: 5px;

                .highlated-date {
                    color: #ffffff !important;
                }

                .date-wrap {
                    color: #ffffff !important;
                }
            }

        }
    }
}

.toast--success {
    background-color: #742F4E;
    color: #ffffff;
}

.exercises-main-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.filtered-row {
    width: 100%;
    background-color: #efefef;
    padding-top: 20px;
    margin-top: -30px;

    @media (max-width: $sm-min) {
        background-color: transparent;
    }

    .filter-active-container {
        margin-top: 15px;
        margin-bottom: -20px;
    }

    .btn-white-bg {
        background: #FFF !important;
    }

    .btn-style {
        min-width: 100px;
        padding: 0px 10px 0px 10px;
        margin-right: -20px;

        @media (max-width: $sm-min) {
            padding: 3px 10px 3px 10px;
        }
    }

}

.btn-white-bg {
    background: #FFF !important;
    margin-right: 4px;
    padding: 2px 10px 2px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.exercise-row {
    width: 100%;
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    top: 50vh;

    .filter-active-container {
        margin-top: 15px;
        margin-bottom: -15px;
    }

    .btn-style {
        min-width: 100px;
        padding: 0px 10px 0px 10px;
        margin-right: -20px;

        @media (max-width: $sm-min) {
            padding: 3px 10px 3px 10px;
        }
    }
}


.filter-button-container {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    margin-bottom: 20px;

    @media (max-width: $sm-min) {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .button-container {
        @media (max-width: $sm-min) {
            width: 100%;
            padding: 0px 3px;
        }
    }

    .btn-style {
        width: 150px;
        padding: 3px 10px 3px 10px;
        margin-right: 30px;

        @media (max-width: $sm-min) {
            margin: 10px 0px;
            width: 100%;
        }
    }
}


.fc-secondary {
    color: #742F4E;
    font-weight: bolder;
    margin-left: 15px;
    margin-bottom: -10px;
    padding: 0;
}

.placeholder-video {
    height: 280px;
    width: 450px;
    border-radius: 10px;
    margin-right: 30px;

    @media (max-width: $sm-min) {
        height: 200px;
        width: 300px;
    }

}

.placeholder-home-training {
    height: 160px;
    width: 630px;
    border-radius: 10px;
    margin-right: 30px;

    @media (max-width: $sm-min) {
        height: 130px;
        width: 100%;
    }
}

.placeholder-home-videos {
    height: 190px;
    width: 350px;
    border-radius: 10px;
    margin-right: 30px;

    @media (max-width: $sm-min) {
        height: 190px;
        width: 300px;
    }
}

.placeholder-program {
    height: 242px;
    width: 240px;
    border-radius: 10px;
    margin-right: 30px;
}

.full-white-bg {
    background-color: #ffffff;
    position: absolute;
    left: 0;
    right: 0;

    @media (max-width: $sm-min) {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.other-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 3px !important;
}

.footer-info {
    max-width: 420px;
    width: 100%;
    display: flex;
    margin: 0 auto;

    p,
    span {
        margin: 0 auto;
        color: #1a1a12;
        font-family: $overpass-light !important;
        text-align: center;
        font-size: 14px;
    }
}

.subtitle-2 {
    p {
        font-family: $overpass-light;
        font-style: normal;
        font-size: 1.25rem;
        color: rgba(0, 0, 0, 1);
    }

    margin: 0 auto;
    overflow: visible;
    margin-top: 10px;
    margin-bottom: 50px;
    text-align: center;

    max-width: 540px;

    @media (max-width: $sm-max) {
        max-width: 340px;
    }

}

.form-label {
    font-family: $overpass !important;
}

.delete-component {
    color: #fff;
    background-color: #EA4335;
    padding: 5px;
}

.scaled-div {
    @media (min-width: $md-max) {
        margin-top: -80px !important;
        transform: scale(0.80);
        // background: #999;
    }
}

.bottom-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .bottom-menu-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 2rem;

        @media (max-width: $sm-min) {
            width: 32px;
            height: 32px;
        }

        img {
            width: 1.8rem;
            height: 1.8rem;

            @media (max-width: $sm-min) {
                width: 28px;
                height: 28px;
            }
        }

    }

    .bottom-menu-logo-traning {
        justify-content: center;
        align-items: flex-start;
        margin-top: -4px;

        img {
            width: 2.7rem;
            height: 2.7rem;

            @media (max-width: $sm-min) {
                width: 50px;
                height: 43px;
            }
        }

    }

    .bottom-menu-logo-exercise {

        img {
            width: 2.2rem;
            height: 2.2rem;

            @media (max-width: $sm-min) {
                width: 32px;
                height: 32px;
            }
        }

    }

    .bottom-menu-logo-smaller {
        img {
            width: 1.7rem;
            height: 1.7rem;

            @media (max-width: $sm-min) {
                width: 24px;
                height: 24px;
            }
        }

    }

    .bottom-menu-text-traning {
        margin-top: 3.5px;
    }

    .bottom-menu-text {
        font-size: 1rem;
        text-transform: capitalize;
        font-family: $poppins-medium;
        margin-bottom: 0px;

        @media (max-width: $sm-min) {
            font-size: 11px;
        }
    }


    .bottom-menu-text.active {
        color: #DE4A47;
    }


}



.home-detail-card {
    .heading-meta-data {
        width: 80px;
        text-align: center;
        text-transform: uppercase;
        margin-left: -16px;
        padding: 8px;
        background: linear-gradient(0deg, #742F4E, #A51254);
        border-radius: 50rem;
        transform: scale(0.60);
    }
}

.announcement-modal {
    padding: 0;

    .heading-meta-data {
        top: -20px;
        left: 20px;
        z-index: 1;
        position: absolute;
        text-transform: uppercase;
        padding: 0.375rem 1.5rem;
        background: linear-gradient(0deg, #742F4E, #A51254);
        border-radius: 50rem;
    }

    .modal-header {
        display: block;
        text-align: center;
        padding: 40px 16px 0 16px;
        border: 0;

        .btn-close {
            position: absolute;
            z-index: 999;
            top: 15px;
            right: 20px;
            font-size: 22px;
            opacity: 1 !important;
        }
    }

    .modal-footer {
        border: 0;
        justify-content: center;
        padding: 12px 6px 45px 12px;
    }

    .details-container {

        text-align: center;
        width: 100%;
        // background-color: #545454;
        margin: 0;

        @media (min-width: $md-max) {
            position: absolute;
            top: 50%;
            transform: translate(-32%, -50%);
        }

        @media (max-width: $lg-min) {
            margin-top: 30px !important;
            padding-bottom: 40px;
        }


        .header-text {
            text-align: center;
            letter-spacing: 0px;
            font-family: $poppins-light;
            color: #0D0D0D;
            font-size: 36px;
            opacity: 1;

            @media (max-width: $lg-max) {
                font-size: 30px;
            }

        }

        .sub-text {
            text-align: center;
            letter-spacing: 0px;
            font-family: $poppins;
            color: #0D0D0D;
            font-size: 20px;
            margin-top: 15px !important;
        }

        .start-date {
            text-align: center;
            letter-spacing: 0px;
            color: #1B1C20;
            opacity: 0.5;
            font-size: 18px;
            margin-top: 15px !important;


        }

        .details-text {
            text-align: center;
            letter-spacing: 0px;
            color: #1B1C20;
            opacity: 1;
            font-size: 18px;
            margin-top: 15px !important;
            margin-bottom: 35px !important;
        }

        a {
            border-radius: 5px;
            text-align: center;
            height: 64px;
            width: 300px !important;
            background-color: #000000;
            padding: 15px 50px 15px 50px;
            font-family: $poppins-light;
            color: #FFF;
            font-size: 12px;
            letter-spacing: 1px;
            cursor: pointer;
        }
    }

    .image-container {
        width: auto;
        // height: 658px;
        overflow: hidden;

        @media (min-width: $lg-max) {
            width: 100%;
            border-top-left-radius: 9px;
            border-bottom-left-radius: 9px;
            margin-top: -60px;
            margin-left: -16px;
            margin-bottom: -16px;

            img {
                // margin-top: 100px;
                // margin-left: -45px;
                width: 100%;
                // transform: scale(1.9);
            }
        }

        @media (max-width: $lg-max) {
            height: 468px;
            width: 100%;
            border-top-left-radius: 9px;
            border-bottom-left-radius: 9px;
            margin-top: -60px;
            margin-left: -16px;
            margin-bottom: -16px;

            img {
                // position: fixed;
                // margin-top: 100px;
                // margin-left: -30px;
                width: 100%;
                // transform: scale(1.8);
            }
        }

        @media (max-width: $lg-min) {
            height: auto !important;
            width: auto;
            border-top-left-radius: 9px;
            border-top-right-radius: 9px;
            border-bottom-left-radius: 0px;

            margin-top: -60px !important;
            margin-left: -16px !important;
            margin-right: -16px !important;
            margin-bottom: 0px !important;

            img {
                margin-top: 0px;
                margin-left: -30px;
                transform: scale(1);
                width: 110%;
            }
        }

        // @media (max-width: $lg-min) {
        //     background: #F01;
        // }

        // @media (min-width: $sm-max) {
        //     transform: scale(0.75);

        // }

        // img {
        //     // position: fixed;
        //     width: 100%;
        //     transform: scale(1.5);
        // }
    }

}

.no-training-container {
    margin-top: 50%;
}

.blank-span {
    height: 30px;
    width: 20px;
}

.reset-completed {
    margin-bottom: -10px;

    p {
        background-color: #FFF;
        border-radius: 50px;
        padding: 3px 10px 3px 10px;
        font-size: 13px;
        font-family: $poppins-medium;
        width: fit-content;
    }
}

.active-training-container {
    margin-top: -55px;

    @media (max-width: $md-min) {
        margin-top: 0px;
    }

    .reset-day-selector {
        margin: 0 auto;
        width: 250px;
        background-color: #fff;
        display: flex;
        padding: 3px;
        flex-direction: row;
        text-align: center;
        align-items: center;
        justify-content: space-around;
        border-radius: 50vh;

    }

    .check-complete {
        @media (max-width: $md-max) {
            margin: 0 0 10px 20px;
        }

        will-change: opacity;

        input:checked {
            ~label {
                .checked-icon {
                    will-change: opacity;
                    display: flex;

                    svg {
                        fill: #ffffff;
                    }
                }

                .uncheck-icon {
                    will-change: opacity;
                    display: none;
                }

                .check-complete-text {
                    will-change: opacity;
                    color: #000000;
                }
            }
        }

        label {
            display: flex;
            justify-content: left;
            align-items: center;
            cursor: pointer;
            margin-left: -16px;

            @media (max-width: $sm-max) {
                margin-left: -2px;
            }

            .check-complete-text {
                color: #1b1c204d;
                opacity: 1;
                margin-left: 5px;

                @media (max-width: $sm-max) {
                    text-transform: uppercase;
                }
            }
        }

        .uncheck-icon,
        .checked-icon {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            padding: 5px;
            border: 1px solid #1b1c204d;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                width: 18px;
                height: 18px;
                fill: #1b1c204d;
            }
        }

        .checked-icon {
            display: none;
            background-color: #000000;
            border-color: #000000;

            svg {
                color: #ffffff;
            }
        }
    }
}

.sale-banner {
    text-align: center;
    padding: 5px;
    margin: -20px auto 40px;
    height: 40px;
    width: 90%;

    p,
    span {

        font-family: $poppins !important;
    }

    @media (max-width: $sm-max) {
        margin-top: -20px;
        text-align: center;
        height: 32px;
        margin-bottom: 20px;
        width: auto;
    }
}

.d-flex-row-center {
    width: 170px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    @media (max-width: $sm-max) {
        width: 130px;
    }
}

.flex-space-even {
    justify-content: space-between;
}

.d-flex-row {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.half-md-card {
    width: 50%;

    @media (max-width: $sm-max) {
        width: 100%;
    }

    .react-datepicker-popper {
        @media (max-width: $sm-max) {
            scale: 100%;
        }

        scale: 75%;
        z-index: 9999;
    }

}

.undo-card {
    height: auto;
    padding: 20px;
    border: none;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    align-content: center;
    justify-content: space-between;

    p {
        color: #742F4E;
        font-size: 14px;
        font-weight: bold;
        margin-top: 5px;
        margin-bottom: 0px;
    }
}

.undo-card-large {
    height: 290px;
    width: 450px;
    padding: 20px;
    border: none;
    margin-bottom: 15px;
    display: flex;
    text-align: center;
    align-items: center;
    align-content: center;
    justify-content: center;

    p {
        color: #742F4E;
        font-size: 14px;
        font-weight: bold;
        margin-top: 5px;
        margin-bottom: 0px;
    }
}

.loading-msg {
    font-family: $poppins;
    font-size: 1.5rem;
    max-width: 320px;
    line-height: normal;
}

.program-nav {
    display: flex;
    justify-content: center !important;
    margin: 0 auto !important;

    .rounded-pill {
        min-width: 0px !important;
        width: 100px !important;

        @media (max-width: $sm-max) {
            width: 80px !important;
        }
    }
}

.disable-mouse-action {
    pointer-events: none;
}



.pill-selector-container {
    display: flex;

    .squared-pill {
        width: 100%;
        height: 30px;
        font-family: $poppins;
        font-size: 12px;
        color: #000;
        cursor: pointer;
        border: 0;
        background-color: #FFF;
        border-radius: 3px;
        margin: 2px;
        text-align: center;

        @media (max-width: $sm-max) {
            height: 37px;
        }
    }

    .squared-pill-sized {
        width: 120px;
        height: 30px;
        font-family: $poppins;
        font-size: 12px;
        color: #000;
        cursor: pointer;
        border: 0;
        background-color: #FFF;
        border-radius: 3px;
        margin: 2px;
        text-align: center;

        @media (max-width: $sm-max) {
            height: 37px;
        }
    }

    .active-pill {
        background-color: $color-primary;
        color: #FFF;
    }

    .disabled-pill {
        background-color: #EDEDED;
        color: #FFF;
    }
}

.audio-player {
    height: 168px;
    width: 100%;
    background: #FFF;
    border-radius: 5px;

    p {
        font-family: $poppins;
        font-size: 16px;
    }

    .buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 70%;
        margin: 0 auto;

        svg {
            height: 30px;
            width: 30px;
        }

        .big-button {
            height: 40px !important;
            width: 40px !important;
        }

    }

    .duration {
        width: 80%;
        margin: 0 auto;
        height: 5px;
        background-color: $color-primary;
        opacity: 0.5;
        border-radius: 2px;
    }
}

.limited-offer {
    height: 40px;
    text-align: center;
    background-color: $color-tertiary;
    color: #FFF;
    width: 100%;
    position: absolute;
    top: 63px;
    left: 0;
    right: 0;

    @media (max-width: $sm-max) {
        top: 43px;
        left: 0;
        right: 0;
        // margin-bottom: 15px;
        // margin-left: -40px;
        // width: 130%;
    }
}



.rhap_container {
    box-shadow: none !important;
    border-radius: 10px !important;
    overflow: unset !important;

    .rhap_main-controls {
        height: 50px !important;
        display: block !important;

        .rhap_play-pause-button {
            height: 50px !important;
        }
    }

    .rhap_button-clear {
        overflow: unset !important;
    }

    .rhap_volume-bar-area {
        align-items: end !important;
    }

    // .rhap_progress-bar-show-download {
    //     background-color: $color-secondary-light !important;
    // }
    .rhap_progress-filled {
        background-color: $color-primary !important;
    }

    .rhap_download-progress {
        opacity: 0.3 !important;
        background-color: $color-primary !important;
    }

    .rhap_progress-indicator {
        background-color: $color-primary !important;
    }

    .rhap_volume-container,
    .rhap_repeat-button {
        display: none;
    }

}

.payment-form {
    padding: 8rem;
}

.back-arrow--modal {
    margin-top: 1rem;
    margin-left: -2rem;
    z-index: 500;
}

.back-arrow--mobile {
    position: fixed;
    padding: 2rem;
    z-index: 500;
}

.slide-left-container {
    top: 59px;
    right: -100%;
    background-color: rgba(256, 256, 256, 0.97);
    width: $side-nav-menu-width + 100;
    // height: 100vh;
    display: flex;
    position: absolute;
    justify-content: center;
    transition: 200ms;
    z-index: 1050;
    // width: 100%;
    overflow-y: auto;
    padding-top: 20px;

    // Small Screen
    @media (max-width: $md-max) {
        background-color: #FFF;
        right: -100%;
        width: 100%;
        top: 0;
        height: 100vh;
    }

    // Active
    &.active {
        right: 0;
        bottom: 0;
        position: fixed;
        transition: 200ms;

        @media (max-width: $md-max) {
            left: 0;
            right: 0;
            bottom: 0;
            position: fixed;
            transition: 200ms;
            overflow-y: auto;
        }
    }
}

.form-floating {

    input,
    select {
        font-family: $overpass-light;
        font-size: 18px;
    }

    label {
        font-family: $overpass-light;
        font-size: 12px;
    }
}

.align-left-justified {
    align-content: start;
}

.email-input {
    padding: 10px;
    width: 90%;
    border: none;
    border-radius: 5px;
    background-color: #efefef;
    text-align: center;
}

.captcha-container {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
}

.misc {
    width: 80%;
    left: auto;
    margin: 70px auto;

    h1 {
        text-transform: capitalize;
        font-size: 2rem !important;
        font-family: $poppins-bold !important;
        color: #1a1a12;
    }

    h2 {
        text-transform: capitalize;
        font-size: 1.5rem !important;
        font-family: $poppins-medium !important;
        color: #1a1a12;
    }
}

.button-delete {
    width: 250px !important;
    padding: 10px 0 10px 0;
}

.email-confirm {
    display: none;
}

.email-confirm-label {
    display: none;
}

.mobile-link {
    display: flex;
    justify-content: end;
    align-items: center;
    margin: 0px -10px 80px 0px;


    h4 {
        color: #742F4E;
        font-weight: bold;
        font-family: $poppins;
        font-size: 1.2rem;
    }
}

.program-video-container {

    div,
    h6 {
        font-size: 1.2rem !important;
    }
}

.video-duration {
    font-family: $poppins-light;
    font-size: 0.9rem !important;
    color: #6C757D;
    margin-left: 24px;
}

.video-duration-nm {
    font-family: $poppins-light;
    font-size: 0.9rem !important;
    margin-left: 1px;
    color: #6C757D;
}

.text-nm {
    font-family: $poppins-light;
    margin-left: 1px;
    color: #6C757D;
}

.selected-card {
    background-color: #FAFDE8 !important;

    div {
        font-family: $poppins !important;
    }
}

.back-button-abs {
    // text-align: center;
    position: absolute;
    // left: 0;
    // top: 0px;
    padding: 0px 10px 0px 10px;
}


.menu-brand {
    @media (max-width: $lg-min) {
        left: 70px;
    }

    .menu-brand-logo {
        width: 144px;
        height: 40px;

        @media (max-width: $lg-min) {
            width: 113px;
            height: 30px;
        }
    }
}

.googlecast-button {
    display: none;
    padding: 0.25rem 0.5rem;

    img {
        width: 1.4em;
        height: 1.4em;
    }

    @media (max-width: $md-w) {
        display: block;
    }
}

.googlecast-button-player {
    // padding: 0.25rem 0.5rem;
    float: left;
    margin-right: 10px;

    img {
        scale: 1.2;
    }
}

.top-margin-negative {
    .MuiTableCell-root {
        span {
            margin-top: -30px !important;
            margin-left: 10px;
        }
    }
}

.text-xs {
    @media (max-width: $sm-max) {
        span {
            font-size: 11px;
            overflow: hidden;
            white-space: nowrap;
        }
    }
}

.no-break {
    overflow: hidden;
    white-space: nowrap;
    float: left;
}

.primary-btn-incomplete {
    border: 1px solid #2699FB !important;
    color: #2699FB !important;
    text-transform: uppercase !important;
    background: none !important;
    font-family: $poppins-light !important;
    width: fit-content !important;
    font-size: 12px !important;
}

.primary-btn-completed {
    border: 1px solid #2699FB !important;
    border-radius: 50%;
    color: #FFFFFF !important;
    text-transform: uppercase !important;
    background: #2699FB !important;
    font-family: $poppins-light !important;
    height: 30px;
    width: 30px;
    text-align: center;
    animation-duration: 3s;
    animation: ease-in;
}

.text-capitalized {
    text-transform: capitalize !important;
}

.hr-light-border {
    color: rgba(0, 0, 0, .300) !important;
}

.garmin-details {

    .MuiTabs-indicator,
    .css-1aquho2-MuiTabs-indicator {
        background-color: #2699FB !important;
    }

    .active-tab {
        font-family: $poppins;
        color: #2699FB !important;
    }

    .inactive-tab {
        font-family: $poppins;
        color: #777;
    }

}

.MuiTableContainer-root {
    box-shadow: unset !important;
    border: 1px solid rgba(0, 0, 0, 0.125) !important;
    border-radius: 0.5rem !important;
}

.motion-container {
    height: 100vh;
    width: 100%;
    overflow-x: hidden;
}

.display-none {
    display: none !important;
}

.exercise-page-list {
    padding-bottom: $space-unit-xl * 2
}


#app-root:has(>.exercise-page-list) {
    margin-bottom: 0
}

.calendar-loader-cont {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2001;
}

.calendar-wait {
    margin-top: 10px;
    color: #742F4E;
    z-index: 1;
}

.month-button {
    border: none;
    background: none;
}

.calendar-date-button {
    width: 100%;
    min-width: 100% !important;
    height: 100%;
    position: absolute !important;
    top: 0;
    left: 0;
    z-index: 1;
}

.calendar-week-cont {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.calendar-date {
    position: absolute;
    top: 5px;
    left: 50%;
    font-size: 0.8em;
    font-weight: 200;
    font-family: $poppins-light;
    color: rgba(0, 0, 0, 0.7);
    transform: translateX(-50%);

    &.active {
        font-weight: 300;
        border-radius: 6px;
        background-color: #E12B2B;
        color: white !important;
        width: 40px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 6px;
        right: 1px;
    }
}

.b-1 {
    border: 1px solid !important;
}

.calendar-table-body {
    position: relative;
    overflow: hidden;
    background-color: #F6F5F5;
}

.calendar-date-value-cont {
    position: absolute;
    font-size: 0.8em;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 1px;

    // .active-days {
    //     background-color: #7e1cce !important;
    // }

    // .inactive-days {
    //     background-color: #c22a2a !important;
    // }

    // .selected {
    //     background-color: #6d88e0 !important;
    // }
}

.calendar-date-value {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 14px;
    border-radius: 8px;
    width: inherit;
    height: inherit;
    background-color: white;
}

.daily-activity-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: $poppins;

    &.laps {
        padding: 0 4px;
        text-wrap: wrap;
    }
}

.daily-activity-calendar {
    font-family: $poppins-extralight;

    &.rd {
        font-family: $poppins-medium;
        color: #f8f8f8 !important;
    }
}

.daily-activity-calendar {
    color: black;
    font-size: 1.75em;
    line-height: 1em;
    font-weight: 100;
}

.daily-activity-desc {
    border-radius: 10px;
    min-width: 35px;
    width: 100%;
    font-size: 0.8em;
    font-weight: normal;
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    top: 23%;
    line-height: 1.4em;
    color: #010101 !important;

    &.rd {
        top: 65%;
        color: #f8f8f8 !important;
        padding: 0 4px;
        text-wrap: wrap;
        font-size: 0.7em;
        line-height: 0.9em;
    }

    &.rd-week-selector {
        top: 20%;
        color: #f8f8f8 !important;
        padding: 0 4px;
        text-wrap: wrap;
        font-size: 0.7em;
        line-height: 0.9em;
    }

    &.week-calendar {
        top: 20%;
    }
}

.daily-activity-cross-train-icons {
    border-radius: 10px;
    min-width: 35px;
    height: 2rem;
    width: 100%;
    // background-color: #ff7600;
    font-size: 0.8em;
    font-weight: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    top: 62%;
}

.p-a {
    position: absolute;
}

.week-cont {
    font-family: $poppins !important;
}

.loader-cont {
    position: absolute;
    width: 100%;
    display: flex;
    height: 100%;
    z-index: 2;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.reorder-div {
    width: 100%;
    padding: 0px !important;
    display: flex;
    position: relative;
}

.options-input {
    width: 100%;
    height: 49px;
    border-radius: 5px;
    padding: 0px 10px;
    text-align: right;
    border: 1px solid rgba(0, 0, 0, 0.4);
    margin-right: -5px;

    &:focus {
        outline: 2px solid #2699FB;
        border-color: transparent;
    }

    &::placeholder {
        opacity: 0.5;
        color: gray !important;
    }

    &::-ms-input-placeholder {
        opacity: 0.5;
        color: gray !important;
    }
}

.activityTab {
    background-color: #DE4A47 !important;
}

.reorder {
    width: 27px;
    height: 18px;
    transform: scale(2.5);
}

.st0 {
    fill: none;
    stroke: #000000;
    stroke-width: 3;
    stroke-miterlimit: 10;
}

.st1 {
    fill: none;
    stroke: #000000;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-miterlimit: 10;
}

.st2 {
    fill: none;
    stroke: #000000;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
}

.daily-activity-input {
    width: 50px;
    height: 30px;
    border-radius: 5px;
    padding: 0px 10px;
    border: 1px solid rgba(128, 128, 128, 0.5);
    margin-right: 5px;

    &:focus {
        border: 2px solid $color-primary;
        outline: none;
    }

    &::placeholder {
        opacity: 0.5;
        color: gray !important;
    }

    &::-ms-input-placeholder {
        opacity: 0.5;
        color: gray !important;
    }
}

.text-area-complete {
    padding: 10px;
    height: 200px;
    border: 1px solid rgba(0, 0, 0, 0.176);
    width: 100%;
    font-size: 16px;
    border-radius: 5px;

    &:focus {
        border: 1px solid $color-primary;
        outline: none;
    }

    &::placeholder {
        opacity: 0.5;
        color: gray !important;
    }

    &::-ms-input-placeholder {
        opacity: 0.5;
        color: gray !important;
    }
}

.underline {
    text-decoration: underline;
}

.pL-18 {
    font-family: $poppins-light !important;
    font-size: 18px;
    line-height: 1.2em;
}

.enter-time {
    border: 1px solid rgba(0, 0, 0, 0.176);
    border-radius: 5px;
    width: calc(100% - 2px);
    height: 45px;
    text-align: center;
    font-family: $poppins-light !important;
    font-size: 16px;

    &:focus {
        border: 1px solid #9FCD2B;
        outline: none;
    }

    &::placeholder {
        opacity: 0.5;
        color: gray !important;
    }

    &::-ms-input-placeholder {
        opacity: 0.5;
        color: gray !important;
    }
}

@keyframes loaderFrame {
    0% {
        background-position: 0 -100%
    }

    100% {
        background-position: 0 100%
    }
}

@keyframes loaderFrame2 {
    0% {
        background-position: -100% 0
    }

    100% {
        background-position: 100% 0
    }
}

.loading-days {
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: loaderFrame;
    animation-timing-function: linear;
    background: linear-gradient(#EFEFF6, #FFFFFF, #EFEFF6);
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: 100% 66%;
    top: -1px;
    box-sizing: content-box;
    border-radius: 5px;
    border: 1px solid #cdcdcd;
    z-index: 2;

    &.toRight {
        background: linear-gradient(to right, #EFEFF6, #FFFFFF, #EFEFF6);
        animation-name: loaderFrame2;
        background-size: 66% 100%;
    }
}

.loading-pace {
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: loaderFrame;
    animation-timing-function: linear;
    background: linear-gradient(#EFEFF6, #FFFFFF, #EFEFF6);
    width: 100%;
    height: 100%;
    background-size: 100% 66%;
    top: -1px;
    box-sizing: content-box;
    border-radius: 5px;
    z-index: 2;

    &.toRight {
        background: linear-gradient(to right, #EFEFF6, #FFFFFF, #EFEFF6);
        animation-name: loaderFrame2;
        background-size: 66% 100%;
    }
}

.ellipsis-1l {
    text-overflow: ellipsis;
    display: block;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.ellipsis-2l, .ellipsis-3l {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.ellipsis-3l {
    -webkit-line-clamp: 3;
}

.notes-ellipsis {
    max-height: 80px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-align: left;
}

.btn-shadow {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    border: 1px solid rgba(0, 0, 0, 0.176);
}

#calendar-week-slider {

    .slick-vertical .slick-slide {
        border: 0 !important
    }
}

.underlineTab {
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #DE4A47;
}

.program-tabs,
.default-tabs {
    background-color: #9FCD2B !important;
    height: 3px;
    bottom: -4px
}


.switch {
    width: 35px;
    height: 19px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 50px;
    padding: 0px 2px;
    cursor: pointer;
}

.switch.fe {
    justify-content: flex-end;
}

.handle {
    width: 15px;
    height: 15px;
    background-color: white;
    border-radius: 40px;
}

.recover-handle-switch {
    width: 80px;
    height: 31px;
    padding: 0px 4px;
}

.recover-handle {
    width: 25px;
    height: 25px;
    background-color: white;
    border-radius: 40px;
}


.fxd-bg {
    background: #000000 0% 0% no-repeat padding-box !important;
}

.video-screen-portrait {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background-color: #1b1c20;

    .fullscreen-content {
        transform: rotate(90deg);
        transform-origin: bottom left;
        width: 100vh;
        height: 100vw;
        margin-top: -100vw;
        // animation: rotateAnimation 1s;
    }
}


.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;

    .loader-container {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(90deg);
    }
}

.overlay-img {
    // img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;

    img {
        scale: 1.8;
        transform: rotate(90deg) !important;
    }
}

.rotate-90deg {
    transform: rotate(90deg) !important;
    margin-top: 15px;
    margin-bottom: 15px;
}

.video-screen-landscape {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background-color: #1b1c20;

    .fullscreen-content {
        height: 100vh;
        width: 100vw;
    }
}

.checkout-google-pay {
    height: 60px !important;
    max-width: 420px !important;
    width: 100% !important;

    & * {
        font-size: 20px !important;
        font-family: Poppins-Medium !important;
    }
}

.sorry-pop-up {

    &.man {
        .MuiModal-backdrop {
            background-image: url(../../public/img-new/misc/man_checking_watch.jpg) !important;
            background-color: #acb8a0
        }
    }

    &.woman {
        .MuiModal-backdrop {
            background-image: url(../../public/img-new/misc/woman_checking_watch.jpg) !important;
            background-color: #bfcfcb
        }

    }

    .MuiModal-backdrop {
        background-repeat: no-repeat;
        background-size: cover;
        height: 62%;
        background-position: center;
    }
}

.sorry-message {
    &.man {
        background: linear-gradient(180deg, rgba(227, 206, 169, 1) 0%, rgba(227, 206, 169, 0) 100%)
    }

    &.woman {
        background: linear-gradient(180deg, rgba(227, 208, 182, 1) 0%, rgba(227, 208, 182, 0) 100%)
    }
}

.sorry-pop-up-desktop {
    &.man {
        background-image: url(../../public/img-new/misc/man_checking_watch.png) !important;
        background-color: #acb8a0;
        height: 400px;
    }

    &.woman {
        background-image: url(../../public/img-new/misc/woman_checking_watch.png) !important;
        background-color: #bfcfcb;
        height: 350px;
    }


    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.show-controls {
    visibility: visible;
    animation: fadeIn 0.33s ease-in-out;
}

.hide-controls {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 0.33s, opacity 0.33s linear;
}

.hidden-element {
    visibility: hidden;
}

.scaled-up {
    scale: 1.2 !important;
}

.modal,
.modal-backdrop {
    z-index: 1500 !important
}

.phase-arrow-rev {
    animation: rotateAnimation180rev 0.5s;
}

.phase-arrow {
    animation: rotateAnimation180 0.5s;
    transform: rotate(180deg);
}

@keyframes rotateAnimation180rev {
    0% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(0deg);
    }
}


@keyframes rotateAnimation180 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(180deg);
    }
}


@keyframes rotateAnimation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(90deg);
    }
}

.login-v2 {
    .slick-dots li button:before {
        font-size: 10px !important
    }
}

.home-detail-card-new {
    width: 100%
}

.p-bold {
    font-family: $poppins-bold !important;
}

.p-regular {
    font-family: $poppins
}

.coupon-btn {
    width: 100%;
    text-align: right;
}

.pe-large {
    padding-right: 24%;
}

.bg-main {
    background: #f6f6f6 !important
}

.rs-drawer .MuiDrawer-paperAnchorRight,
.MuiDrawer-paperAnchorBottom {
    @extend .bg-main;
}

.rs-drawer-light {
    @extend .bg-light;
}

.header-menu {
    height: 60px;
    box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.15) !important;
}

.pt-6 {
    padding-top: 60px !important;
}

.btm-fa-icons {
    line-height: 1.4em !important;
    font-size: 26px;
    color: #010101;

    &.fa-active {
        color: #9fcd2b
    }
}

.player-container {
    @media (max-width: $md-max) {
        position: fixed;
        z-index: 3;
        margin-top: -1px;
        box-shadow: 0px 5px 5px rgba(128, 128, 128, 0.2) !important;
    }
}

.top-fa-icons {
    font-size: 22px;
    line-height: 1.2em !important;
    color: #010101;

    &.fa-active {
        color: #9fcd2b
    }
}

.bot-fa-icons {
    font-size: 26px;
    line-height: 1.4em !important;
    color: #010101;

    &.fa-active {
        color: #9fcd2b
    }
}

.header-icons {
    height: 26px;
    line-height: 1.2em !important;
    color: #010101;
}

.footer-icons {
    height: 26px;
    line-height: 1.4em !important;
    color: #010101;
}

.flag-duo {
    &::after {
        color: white !important;
    }
}

.Mui-focused {
    border-color: #9fcd2b !important;

    .MuiOutlinedInput-notchedOutline {
        border-color: #9fcd2b !important;
    }
}

.MuiInputLabel-shrink.Mui-focused {
    color: #9fcd2b !important;
}

.MuiPickersLayout-root {
    .MuiButton-root {
        font-weight: bold !important
    }
}

.MuiPickersDay-root.Mui-selected {
    background-color: #9fcd2b !important
}

.casting {
    opacity: 10;

    img {
        z-index: -1;
    }

    display: flex;
    justify-content: center;
    align-items: center;

    h2 {
        text-decoration: none !important;
        text-transform: none !important;
        font-family: $poppins;
        position: absolute;
        z-index: 1;
        color: #FFFF;
        font-size: 27px;
        opacity: 999;
    }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.default-mw {
    max-width: $md-w !important;
}

.sm-mw {
    max-width: $sm-w !important;
}

.default-bs {
    box-shadow: 0px 2px 6px rgba(128, 128, 128, 0.1) !important;
}

.no-bs {
    box-shadow: none !important;
}

.header-logo {
    width: 144px;
    height: 40px;

    @media (max-width: $lg-min) {
        width: 113px;
        height: 30px;
    }
}

.home-percent-cont {
    position: absolute;
    font-family: Poppins !important;
    font-size: 12px !important;
    color: dimgray;
    bottom: 2px;
    right: 10px;
}

.transition-height {
    transition: height 1s;
}

.transition-height-halfsec {
    transition: height 0.5s;
}

.transition-n {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
    -o-transition-property: none !important;
    -moz-transition-property: none !important;
    -ms-transition-property: none !important;
    -webkit-transition-property: none !important;
    transition-property: none !important;
}

.transition-all-quarter {
    transition: all 0.25s;
}

.transition-all {
    transition: all 0.5s;
}

.transition-all-1 {
    transition: all 1s;
}

.c-w {
    color: 'white';
}

.search-filters-scroll {
    padding-left: 0px !important;
}

.midfoot-program-container {
    .rhap_container {
        height: 100% !important;
        border-radius: 5px !important;
        @extend .default-bs;

        .rhap_main-controls {
            height: 40px !important;
            display: flex !important;

            & button {
                display: flex !important;
                align-items: center !important;
                justify-content: center !important;
                height: 100% !important;
            }
        }
    }

    .rhap_stacked .rhap_controls-section {
        margin-top: 0 !important
    }

    .rhap_header {
        margin-bottom: 5px !important;
    }
}

.h-100 {
    height: 100% !important;
}

.duo-tone {
    --fa-primary-color: #010101;
    --fa-secondary-color: #9fcd2b;
    --fa-secondary-opacity: 1;
}

.duo-tone-black {
    --fa-primary-color: #010101;
    --fa-secondary-color: #010101;
    --fa-secondary-opacity: 1;
}

.duo-tone-red {
    --fa-secondary-color: red;
}

.duo-tone-BW {
    --fa-primary-color: #ffffff;
    --fa-secondary-color: #000000;
    --fa-secondary-opacity: 1;
}

.duo-tone-WG {
    --fa-primary-color: #9fcd2b;
    --fa-secondary-color: #ffffff;
    --fa-secondary-opacity: 1;

    &.uncheck {
        --fa-primary-color: #ffffff;
    }

    &.xmark {
        --fa-primary-color: red;
    }
}

.duo-tone-GW {
    --fa-primary-color: #ffffff;
    --fa-secondary-color: #9fcd2b;
    --fa-secondary-opacity: 1;
}

.duo-tone-disabled {
    --fa-primary-color: gray;
    --fa-secondary-color: lightgray;
    --fa-secondary-opacity: 1;
}

.ellipsis-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.hide-scroll {
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    overflow-x: auto;
    overflow-y: hidden;
    cursor: pointer;

    // Scrollbar
    &::-webkit-scrollbar {
        width: 0 !important;
        height: 0 !important;
    }
}

.color-change {
    animation: colorChange 0.5s linear 0s 1 normal forwards !important;
}

.jump-icon {
    animation: jump 0.50s ease-in-out !important;
}

.delay-0 {
    animation-delay: 0s !important;
}

.delay-1 {
    animation-delay: 0.25s !important;
}

.delay-2 {
    animation-delay: 0.5s !important;
}

.delay-3 {
    animation-delay: 0.75s !important;
}

.delay-4 {
    animation-delay: 1s !important;
}

.mt-n100 {
    margin-top: -200px;
}

.progress-center {
    top: 50%;
    transform: translateY(-125px);
    width: 160px;
    margin-right: 0px;
    background: transparent
}

.progress-top {
    top: 0;
    padding: 50px 0px 0px 0px;
    width: 100%;

    &.up {
        position: fixed !important;
        z-Index: 1;
    }

    &.left {
        top: calc((700px / 2) - 32px);
        margin-right: 735px;
        padding: 0px;
        width: 700px;
    }
}

.rotate-n90 {
    transform: rotate(-90deg);
}

.rotate-90 {
    transform: rotate(90deg);
}

.change-height {
    width: 160px;
    animation: hundred-to-auto 0.5s forwards;
}

.change-height-rev {
    margin-top: -200px;
    animation: hundred-to-auto 0.5s reverse forwards;
}

.visibility-animation {
    visibility: hidden;
    animation: visible 0.5s linear 0.5s forwards;
}

.slide-to-left {
    transform: translateX(100px);
    animation: visible 0.5s linear 0.5s forwards, right-to-left-100 1s forwards;
}

.slide-to-right {
    transform: translateX(-100px);
    animation: visible 0.5s linear 0.5s forwards, right-to-left-100 1s forwards;
}

.start-right-to-left {
    display: flex;
    flex-direction: column;
    height: 120px;
    animation: right-to-left-100 0.5s forwards;
}

.start-left-to-right {
    display: flex;
    flex-direction: column;
    height: 120px;
    animation: left-to-right-n100 0.5s forwards;
}

.display-none-animation {
    animation: display-none 0.5s linear 1 normal forwards, right-to-left-n100 0.5s forwards;
}

.body-height-animation {
    height: 0px;
    opacity: 0;
    animation: hundred-zero-to-hundred 0.75s linear forwards;
}

.body-height-animation-1 {
    opacity: 0;
    animation: hundred-zero-to-hundred 1s linear 0.5s forwards;
}

.build-steps-img {
    background-image: url(../../public/img-new/homepage/cover_images/desktop/training_create_4.png) !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.3;
}

.background-bottom {
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
}

.overflow-y-auto {
    overflow-y: auto;
}

.MuiPickersLayout-actionBar {
    .MuiButton-text {
        color: #010101 !important;
        border: 1px solid #9fcd2b !important;
        font-family: $poppins !important;
    }

    button:last-child {
        background-color: #9fcd2b !important;
    }
}

.announcement-body {
    .slick-slider {
        width: 100% !important;
    }

    .slick-dots {
        height: 50px;
        width: calc(100% - 164px) !important;
        margin-left: 82px !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        margin-bottom: 26px !important;
    }
}

@keyframes jump {
    0% {
        transform: translateY(0);
    }

    25% {
        transform: translateY(-30px);
    }

    75% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes pad-5-animation {
    from {
        padding-left: 3px;
        padding-right: 4px;
    }

    to {
        padding-left: 5px;
        padding-right: 5px;
    }
}

@keyframes right-to-left-100 {
    from {
        transform: translateX(100px);
    }

    to {
        transform: translateX(0px);
    }
}

@keyframes left-to-right-100 {
    from {
        transform: translateX(0px);
    }

    to {
        transform: translateX(100px);
    }
}

@keyframes left-to-right-n100 {
    from {
        transform: translateX(-100px);
    }

    to {
        transform: translateX(0px);
    }
}



@keyframes right-to-left-20 {
    from {
        transform: translateX(20px);
    }

    to {
        transform: translateX(0px);
    }
}

@keyframes right-to-left-n100 {
    from {
        transform: translateX(0px);
    }

    to {
        transform: translateX(-100px);
    }
}



@keyframes display-none {
    from {
        display: flex;
        opacity: 1;
    }

    to {
        display: none;
        opacity: 0;
    }
}


@keyframes visible {
    from {
        visibility: hidden;
        opacity: 0;
    }

    to {
        visibility: visible;
        opacity: 1;
    }
}

@keyframes width-zero-300 {
    from {
        width: 0px;
        opacity: 0;
    }

    to {
        width: 300px;
        opacity: 1;
    }
}

@keyframes hundred-zero-to-hundred {
    from {
        height: 0px;
        opacity: 0;
    }

    to {
        height: 100%;
        opacity: 1;
    }
}

@keyframes hundred-to-auto {
    from {
        margin-top: -200px;
        height: 50px;
        width: 160px;
    }

    to {
        margin-top: 0px;
        padding: 16px 0px 0px 0px;
        height: 100%;
        width: 100%;
    }
}

@keyframes colorChange {
    from {
        background-color: white;
        border-color: #9fcd2b;
    }

    to {
        background-color: #e7e7e7;
        border-color: transparent
    }
}

@keyframes move {
    from {
        align-items: center !important;
    }

    to {
        align-items: flex-start !important;
    }
}


@media (min-width: 901px) {
    .d-default-block {
        display: block !important;
    }

    .d-default-none {
        display: none !important;
    }

    .recovery-assessment {
        padding-bottom: 100px;
    }
}

@media (max-width: 380px) {
    .d-none-sm {
        display: none !important;
    }
}


.plan-panel {
    margin: 2rem;
    height: 40%;
}

.plan-panel--buttons {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 0.5rem;
}

@media (max-width: 444px) {

    .plan-panel--buttons {
        justify-content: space-between;
        margin: 20px 0;
        flex-direction: column;
    }
}

.card-slanted {
    clip-path: polygon(0 0, 100% 0, 80% 100%, 0% 100%);
}

.card-slanted--reverse {
    clip-path: polygon(10% 0, 100% 0, 100% 100%, 0% 100%);
}

.flip {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.overflow-visible {
    .MuiPaper-elevation {
        overflow: visible !important;
    }
}

.no-overscroll {
		overscroll-behavior: none;
		-webkit-overflow-scrolling: touch;
}

$primary: #9fcd2b;
$primary-light: #9fcd2b33;
$primary2-light: #fcc26a33;
$primary3-light: #bfd38c;
$primary-dark: #5e7919;
$analogous-green: #6bcd2b;
$orange-dark: #FF9A00;
$orange-light: #FCC26A;
$gray-light: #f2f2f2;
$gray-input: #0000003b;
$gray-mid: #e7e7e7;
$gray-dark: #4a4a4a;
$gray-disabled-light: #cbcbcb;
$gray-disabled-dark: #555555;
$accent-purple: #6b2bcd;
$blue-comp: #2b9fcd;
$pink-comp: #cd2b6e;
$danger-red: #dc3545;

.rsButtonStyle {
		text-decoration: none !important;
		text-transform: none !important;
		color: #010101 !important;
		font-family: $poppins-medium !important;
		font-size: 16px !important;
		min-width: 0px !important;
		border: 1px solid transparent !important;
		border-radius: 5px !important;
		width: 100%;
		padding: 10px;
		cursor: pointer;
		* {
			font-size: 16px;
			font-family: $poppins-medium;
		}

		& .imposed {
			text-transform: uppercase !important;
			font-size: 14px !important;
			padding: 8px 10px !important;
		}
}

.weekly-bar-chart {
	* {
		font-size: 14px;
		font-family: $poppins;
		text-transform: uppercase;
	}
}

.white-text {
		color: $white !important;
}

.white-bg {
		background-color: $white !important;
}

.primary-bg {
		background-color: $primary !important;
}

.primary-text {
		color: $primary !important;
}

.primary-border {
		border-color: $primary !important;
}

.primary-dark-text {
		color: $primary-dark !important;
}

.primary-bg-hover {
	&:hover {
		background-color: $primary-light !important;
	}
}

.primary-bg-active {
	&:active {
		background-color: $primary-light !important;
	}
}

.primary2-bg-hover {
	&:hover {
		background-color: $primary2-light !important;
	}
}

.primary2-bg-active {
	&:active {
		background-color: $primary2-light !important;
	}
}

.primary3-bg {
	background-color: $primary3-light !important;
}

.analogous-bg {
		background-color: $analogous-green !important;
}

.analogous-text {
		color: $analogous-green !important;
}

.orange-border {
		border-color: $orange-dark !important;
}

.orange-bg {
		background-color: $orange-light !important;
}

.gray-disabled-bg {
		background-color: $gray-disabled-light !important;
}

.gray-disabled-text {
		color: $gray-disabled-dark !important;
}

.gray-input-border {
		border-color: $gray-input !important;
}

.gray-mid-bg {
		background-color: $gray-mid !important;
}

.gray-dark-border {
		border-color: $gray-dark !important;
}

.gray-dark-bg {
		background-color: $gray-dark !important;
}

.gray-dark-text {
		color: $gray-dark !important;
}

.accent-bg {
		background-color: $accent-purple !important;
}

.blue-text {
		color: $blue-comp !important;
}

.blue-text-decoration {
		text-decoration-color: $blue-comp !important;
}

.blue-bg {
		background-color: $blue-comp !important;
}

.pink-bg {
		background-color: $pink-comp !important;
}

.danger-bg {
		background-color: $danger-red !important;
}

.danger-text {
		color: $danger-red !important;
}

.white-bg-active {
	&:active {
		background-color: $white !important;
	}
}

.border-1 {
	border-width: 1px !important;
}

.border-solid {
	border-style: solid !important;
}

.opacity-half {
		opacity: 0.5 !important;
}

.borderless {
		border: none !important;
}

.backdrop-mui {
	background-color: rgba(0, 0, 0, 0.5) !important;
}

.lh-1-2 {
	line-height: 1.2 !important;
}

.center-placeholder {
	&::-webkit-input-placeholder {
		position: absolute;
		text-align: center;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&:-moz-placeholder {
		position: absolute;
		text-align: center;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&::-moz-placeholder {
		position: absolute;
		text-align: center;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&:-ms-input-placeholder {
		position: absolute;
		text-align: center;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.multi-line-ellipsis {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 5; /* Number of lines to display */
}

.admin-ui-colors {
	width: 50px;
	height: 50px;
	margin-right: 10px;

}

.home_mobile_logo {
	position: absolute;
	left: 0px;
	height: calc(100% - 25px);
	width: auto;
}
