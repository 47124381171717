// Bootstrap Overrides
$enable-negative-margins: true;
$box-shadow: 0 0.5rem 1rem rgba($black, 0.2);
$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.1);
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.3);
$box-shadow-inset: inset 0 1px 2px rgba($black, 0.1);

/**
 * Variables
 */

// Space Units
$space-unit-xs: 0.125rem;
$space-unit-sm: $space-unit-xs * 2;
$space-unit-md: $space-unit-sm * 2;
$space-unit-lg: $space-unit-md * 2;
$space-unit-xl: $space-unit-lg * 2;

// Font Sizes
$h1-size: 2.25rem;
$h1-mobile-size: 1.5rem;
$h1-weight: 500;
$h1-spacing: 2px;
$h1-spacing-mobile: 1px;

$h2-size: 1.25rem;
$h2-mobile-size: 1rem;
$h2-weight: $h1-weight;
$h2-spacing: $h1-spacing;
$h2-spacing-mobile: $h1-spacing-mobile;

$h3-size: 1.5rem;
$h3-mobile-size: $h3-size;
$h3-weight: 700;
$h3-spacing: -1px;

$h4-size: $h2-size;
// $h4-size: 1rem;
$h4-mobile-size: $h2-mobile-size;
$h4-weight: 400;

$h5-size: 1rem;
// $h5-size: 0.9rem;
$h5-mobile-size: 0.9rem;
$h5-weight: 500;

$h6-size: 0.9rem;
$h6-weight: 400;

$h7-size: 1.2rem;
$h7-weight: 400;
$h7-weight-mobile: 700;

$menu-text-size: 0.8rem;
$menu-text-mobile-size: 1rem;
$menu-text-weight: 400;
$menu-text-mobile-weight: 500;
$button-text-size: 0.9rem;
$button-text-mobile-size: 0.7rem;

$master-font-size: calc(0.75em + 0.5vw);
$content-font-size: 1rem;
$content-line-height: 1.7;
$no-line-height: 1.2;

// Breakpoints
$xxl-max: 1600px;
$xxl-middle: $xxl-max - 1px;
$xxl-min: 1400px;
$xl-max: $xxl-min - 1px;
$xl-min: 1200px;
$lg-max: $xl-min - 1px;
$lg-min: 992px;
$md-max: $lg-min - 1px;
$md-min: 768px;
$sm-max: $md-min - 1px;
$sm-min: 576px;
$xs-max: $sm-min - 1px;
$xs-min: 0;
$xxs-max: 410px;

// Colors
$color-primary: #9fcd2b;
$color-secondary: #b7ff00;
$color-secondary-light: #c6fd50;
$dark-card-bg: #1a1a12;
$dark-card-bg-secondary: #1a1a12a8;
$link-opacity-percent: 0.9;
$secondary-percent: 0.55;

// Cards
$detail-card-width: 625px;
$detail-card-mobile-height: 140px;
$mobile-card-width: 300px;
$small-card-height: 120px;
$medium-card-height: 162px;
$medium-card-width: 240px;
$large-card-max-width: 375px;
$large-card-height: 190px;

// Buttons
$circle-lg-size-desktop: 4.5rem;
$circle-lg-size-mobile: 4rem;
$circle-md-size: 3rem;
$home-play-button-sm-top: 35%;
$fixed-lg-width: 15rem;
$fixed-md-width: 10rem;
$fixed-sm-width: 8rem;
$nav-pill-button-width: 150px;
$mechanix-play-button-size: 32px;

// Icons
$arrow-icon-size: 3rem;
$arrow-icon-stroke: 3px;
$heart-icon-size: 2rem;
$detail-heart-icon-adjust: 51.75px;

// Misc
$home-row-width: 1286px;
$max-container-width: 1520px;
$max-container-width-1170: 1170px;

$main-margin-top: 70px;
$main-margin-top-mobile: 70px;
$main-margin-top-mobile-sm: 60px;

$jumbotron-margin-top: 20px;
$jumbotron-margin-top-mobile: $jumbotron-margin-top - 5px;

$search-margin-top: $main-margin-top + 10px;
$search-margin-top-mobile: $main-margin-top-mobile + 20px;

$img-fill-height: 729px;
$img-fill-sm-height: 24rem;

$form-padding-md: 3rem;
$form-padding-lg: 12rem;

$card-lower-logo-top: 220px;
$invite-card-min-height: 350px;
$recurly-card-height: 24px;
$large-aspect-ratio: 24 / 10;
$default-aspect-ratio: 16 / 9;
$navbar-toggler-height: 38px;
$card-title-line-margin: 1.75rem;
$nav-menu-span-width: 29px;
$side-nav-menu-font-size: 2rem;
$side-nav-menu-width: 250px;
$side-nav-menu-item-height: 40px;
$responsive-text-card-margin: 20px;
$card-body-bottom-margin: 45px;
$card-body-line-height: 27px;
$video-detail-card-text-left: -36.5px;
$text-card-padding: 20px;

// Hero Image
$hero-image-width: 20rem;
$hero-image-md-width: 15rem;
$hero-image-sm-width: 11rem;
