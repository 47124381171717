//Variables
@import './variables.scss';

.build-plan {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Poppins';
    }

    p {
        font-family: 'Poppins';
        font-size: $content-font-size;
        line-height: $content-line-height;
    }

    li {
        font-size: $content-font-size;
        line-height: $content-line-height;
    }

    div,
    span,
    li,
    div.form-control,
    input.form-control,
    select.form-select {
        font-family: 'Poppins';
    }

    .cross-train-distance {

        .icon {

            @media (min-width: $md-min) {
                width: 20px;
                margin-top: -30px;
                float: right;
                margin-right: 10px;
            }


            @media (max-width: $sm-max) {
                width: 15px;
                margin-top: -20px;
                float: right;
                margin-right: 10px;
            }
        }

        @media (max-width: $sm-max) {
            font-size: 20px;
        }

        @media (min-width: $md-min) {
            font-size: 30px;
        }
    }

    .step-label {
        font-size: 24px;
        font-weight: 800;
    }

    .font-sm-20 {
        @media (max-width: $sm-min) {
            font-size: 20px;
        }
    }

    .font-sm-9 {
        @media (max-width: $sm-min) {
            font-size: 11px !important;
            font-weight: 800;
        }
    }

    .font-sm-7 {

        @media (max-width: $sm-min) {
            font-size: 9px !important;
            font-weight: 800;
        }

        @media (max-width: $md-max) {
            font-size: 13px;
            font-weight: 800;
        }
    }

    .font-sm-13 {
        @media (max-width: $sm-min) {
            font-size: 11px !important;
            font-weight: bold;
        }
    }

    .font-margin-build {
        // @media (min-width: $lg-min) {
        //     margin-bottom: -10px;
        //     margin-top: -16px;
        // }

        @media (min-width: $sm-max) {
            margin-bottom: 26px;
            margin-top: -18px;
        }
    }

    .font-margin-plan--units {
        // @media (min-width: $lg-min) {
        //     margin-bottom: 8px;
        //     margin-top: -16px;
        // }

        @media (max-width: $sm-min) {
            // margin-bottom: 25px;
            margin-top: 10px;
        }
    }

    .mx-xs-auto {
        @media (max-width: $sm-max) {
            margin: 0 auto;
        }

        @media (min-width: $md-min) {
            margin: 0 auto;
        }
    }

    .plan-text-small {
        @media (max-width: $sm-min) {
            font-size: 9px !important;
        }
    }

    .distance-info-box {
        max-width: $md-max;
        margin: 0 auto;

        .plan-options-box {
            cursor: pointer;
            padding: 10px;
            border: 1px solid $color-primary;
            border-radius: 6px;
            height: 110px;
            max-width: $sm-min;
            display: flex;
            align-items: center;

            .details {
                display: flex;
                flex-direction: column;
                text-align: left;
                width: auto;

                p {
                    margin: -6px;
                }

            }

            .bg-gray {
                background-color: #5a5a5a;
            }

            .distance--title {
                display: flex;
                flex-direction: column;
                text-align: center;
                justify-content: center;
                width: 40%;
                color: #B7FF00 !important;
                font-weight: 800;

                .small {
                    font-size: 15px;
                    margin-top: 12px;


                    @media (max-width: $sm-min) {
                        font-size: 12px;
                        font-weight: bold;
                    }
                }

                .secondary {
                    font-size: 20px;
                    font-weight: bolder;

                    @media (max-width: $sm-min) {
                        font-size: 15px;
                    }
                }

                .secondary2 {
                    font-size: 25px;
                    font-weight: bolder;

                    @media (max-width: $sm-min) {
                        font-size: 15px;

                    }
                }

                .default {
                    font-size: 35px;
                    font-weight: bolder;

                    @media (max-width: $sm-min) {
                        font-size: 30px;
                    }
                }

                .subtitle {
                    font-size: 15px;

                    @media (max-width: $sm-min) {
                        font-size: 12px;
                        font-weight: bold;
                    }
                }
            }

            &:hover {
                background-color: $dark-card-bg !important;
            }

        }

    }


    .text-caps {
        text-transform: none;
    }

    .race-selection-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .race-selection {

            &:hover {
                background-color: #FAFAFA;
                cursor: pointer;
            }

            max-width: 500px;
            border-radius: 10px;
            overflow-x: scroll;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: start;

            .title {
                text-align: center;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                height: 65px;
                min-height: 65px;
                width: 65px;
                min-width: 65px;
                border-radius: 50%;
                border: 2px solid #742F4E;
                color: #742F4E;
                font-size: 25px;
                font-family: $poppins-semibold;
            }

            .subtext {
                width: 100%;
                height: auto;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-family: $poppins-medium;
                font-size: 21px;

            }

            .details {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .details-wrapped {
                p {
                    min-width: 220px !important;
                    min-height: auto;

                    @media (max-width: $xxs-max) {
                        width: 50% !important;
                    }
                }
            }

            .type-container {
                min-width: 320px;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                @media (max-width: $xxs-max) {
                    width: 90%;
                }
            }

            .type-value {
                padding: 1px 13px;
                border-radius: 7px;
                width: auto;
                background-color: #742F4E;
                color: #FFFFFF;
                font-size: 15px;
                margin-right: 10px;
            }

        }
    }

    .bg-light-gray {
        @media (min-width: $md-min) {
            background-color: #FAFAFA;
            border-radius: 10px;
        }
    }

    .selected-card {
        background-color: #FAFDE8 !important;
    }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.race-selection::-webkit-scrollbar {
    display: none;
}

.w-auto-view {
    width: 50%;

    @media (max-width: $sm-max) {
        width: 100%;
    }
}

/* Hide scrollbar for IE, Edge and Firefox */
.race-selection {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}