.checkout {

    h1,
    h2,
    h3,
    h5,
    h6 {
        margin-top: -30px;

        @media (max-width: $md-min) {
            margin-top: 0;
        }

        overflow: visible;
        // white-space: nowrap;
        text-align: center;
        font-family: $overpass !important;
        font-style: normal;
        font-weight: bold;
        font-size: 2rem;
        color: rgba(26, 26, 18, 1);
        letter-spacing: -1px;
        text-transform: none;
    }

    h4 {
        margin-top: -30px;

        @media (max-width: $md-min) {
            margin-top: 0;
        }

        overflow: visible;
        white-space: nowrap;
        text-align: center;
        font-family: $overpass-medium !important;
        font-style: normal;
        font-weight: bold;
        color: rgba(26, 26, 18, 1);
        letter-spacing: -0.1px;
        text-transform: none;
    }

    p {
        font-family: 'Overpass-Medium';
    }

    li {
        font-size: $content-font-size;
    }

    span {
        font-family: 'Overpass-Light';
    }

    margin-top: -40px;

    @media (max-width: $md-min) {
        margin-top: -17px;
    }

    .checkout-video {
        width: 40%;

        @media (max-width: $md-max) {
            width: 100%;
        }

        .btn-circle {
            width: 50px;
            height: 50px;
        }
    }

    .subtitle {
        p {
            font-family: $overpass-light;
            font-style: normal;
            font-size: 1.25rem;
            color: rgba(0, 0, 0, 1);
        }

        margin: 0 auto;
        overflow: visible;
        max-width: 90%;
        margin-top: 10px;
        margin-bottom: 50px;
        text-align: center;
    }

    .subtitle2 {
        margin: 0 auto;
        overflow: visible;
        max-width: 420px;
        margin-top: 10px;
    }

    .popular {
        width: 130px;
        border-radius: 4px;
        color: #FAFAFA;
        background-color: #742e4e;
        margin: 0 auto;
        text-align: center;
        margin-top: -31px;
        margin-bottom: -10px;
        white-space: nowrap;

        p {
            font-family: $poppins;
            font-size: 13px;
            padding-top: 4px;
            padding-bottom: 4px;
            margin-left: 10px;
            margin-right: 10px;
        }

    }

    .selectorNew {
        & .popular {
            background-color: black;
        }
    }

    .selector {
        margin: 0 auto;
        max-width: 420px;
        height: 80px;
        background-color: #F4F4F4;
        border: 1px solid #CACACA;
        border-radius: 6px;
        margin-bottom: 7px;
        padding: 13px 10px 13px 10px;
        cursor: pointer;

        .upper-text {
            p {
                font-size: 11px;
                font-family: $overpass !important;
            }

            margin: 0;
            display: flex;
            color: #969696;
            justify-content: space-between;
        }

        .lower-text {
            p {
                font-size: 20px;
                font-family: $overpass;
            }

            color: rgba(26, 26, 18, 1);
            margin: 0;
            display: flex;
            justify-content: space-between;
        }
    }

    .selectorNew {
        background-color: #FFFFFF;
        border: 4px solid #FFFFFF;
        box-shadow: 0px 2px 6px rgba(128, 128, 128, 0.1);
    }

    .selected {
        background-color: #242021;
        border: 1px solid #747474;

        .lower-text p {
            color: #F4F4F4;
        }
    }

    .newDesign {
        background-color: #FFFFFF;
        border: 4px solid #9fcd2b;

        .lower-text p {
            color: #010101;
        }
    }

    .trial-info {
        .trial-ends {
            max-width: 420px;
            margin: 0 auto;
            color: rgba(26, 26, 18, 1);
            display: flex;
            justify-content: space-between;

            p {
                font-size: 25px;
                font-family: $overpass;
            }
        }

        .billing-and-trial-period {
            max-width: 420px;
            margin: 0 auto;
            display: flex;


            p {
                font-family: $overpass-medium;
                color: #747474;
                line-height: 1.4rem;
            }

        }
    }

    .active-button {
        background-color: #742F4E !important;

        p {

            color: #FAFAFA !important;
        }
    }

    .process-button {
        max-width: 420px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        background-color: #F4F4F4;
        border-radius: 6px;
        border: none;

        p {
            padding: 20px 0;
            margin: 0 auto;
            color: #6f6f6f;
            font-size: 20px;
            font-family: $poppins;
        }
    }

    .chosen-plan-info {
        max-width: 420px;
        width: 100%;
        display: flex;
        margin: 0 auto;

        p {
            margin: 0 auto;
            color: #6f6f6f;
            font-family: $overpass-light;
            text-align: left;
            font-size: 1rem;
        }
    }

    .footer-info {
        max-width: 420px;
        width: 100%;
        display: flex;
        margin: 0 auto;

        p {
            margin: 0 auto;
            color: #6f6f6f;
            font-family: $overpass-light;
            text-align: center;
            font-size: 14px;
        }
    }

    .stripe-footer {
        max-width: 420px;
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        margin: 0 auto;
        align-items: center;
        justify-content: center;

        .guard {
            height: 50px !important;
        }

        img {
            height: 30px;
        }
    }

    .other-btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        line-height: 3px;
    }

    .coupon-link {
        text-align: right;
        width: 100%;
        max-width: 420px;
        margin: 15px auto;
        cursor: pointer;

        p {
            font-size: 18px !important;
            color: #555;
        }

    }
}

.checkout,
.payment-details-cont {
    .payment-details-form {
        margin: 70px auto;
        max-width: 420px;

        input[type=input]::placeholder {
            color: #B3B3B7 !important;
            font-family: $overpass-medium;
            padding-top: 10px;
        }

        input[type=input] {
            font-family: $overpass-medium;
            padding: 9px 10px 5px 10px;
            color: #555;
        }


        .firstName,
        .lastName,
        .postalCode {
            color: #555;
        }

        @media (max-width: $md-min) {
            margin: 50px auto;
        }

        h4 {
            text-align: left;
            font-family: $overpass-medium;
            text-transform: uppercase;
            font-weight: normal;
            color: #747474;
        }
    }
}

.payment-details-cont .payment-details-form {
    margin: 0px;
}

.checkout-v2-trial p {
    margin-bottom: 0px;
}

.coupon-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .coupon-form {
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
            font-size: 17px;
            font-family: $poppins-light !important;
        }

        button {
            padding: 20px;
            font-size: 14px;
            width: 70%;
            margin-top: 10px;
        }


        input[type=text] {
            padding: 15px 20px;
            width: 70%;
            border: 1px solid #B3B3B7;
            border-radius: 5px;
            color: #555;
            font-size: 25px;
            text-align: center;

            @media (max-width: $md-max) {
                border: none;
                border: 1px solid #B3B3B7;

            }
        }
    }
}