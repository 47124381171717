.survey-form-container {
    background: #fff;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;

    .btn {
        font-size: 1.25rem !important;
    }

    .content {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .headline {
            font-family: $poppins;
            text-transform: none;
            font-size: 2.25rem;
            letter-spacing: 0px;
        }

        .subhead {
            margin-top: 15px;
            font-family: $overpass-light;
            font-size: 1.5rem;
            line-height: normal;
        }

        .start-btn {
            margin-top: 15px;
            color: #fff;
            border: none;
            width: 357px;
            height: 67px;
            background-color: #1a1a12;
            border-radius: 10px;
            font-size: 1.5rem;

            @media (max-width: $sm-max) {
                width: 310px;
            }
        }

        .skip-link {
            text-decoration: underline;
            margin-top: 20px;
            font-size: 1.20rem;
        }
    }

    .content-questions {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top: 10%;
        padding: 20px;

        @media (max-width: $sm-max) {
            margin-top: 25%;
        }

        .question {
            font-size: 1.75rem;
            font-family: $overpass-light;
            text-transform: none;
            width: 264px;
            letter-spacing: 0px;
            line-height: 1.4;
        }

        .indicators {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            margin-bottom: 35px;

            .indicator {
                height: 4px;
                width: 52px;
                background-color: #F2F2F2;
                border-radius: 4px;
                margin: 0px 2px 0px 2px
            }

            .active {
                background-color: #B7FF00;
            }
        }

        .answers {

            margin-top: 20px;

            display: flex;
            flex-direction: column;
            justify-content: space-around;

            .answer {
                height: 75px;
                width: 338px;
                background-color: #F2F2F2;
                // font-weight: bold;
                margin: 5px 0;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                p {
                    margin-top: 15px;
                    max-width: 230px;
                    font-size: 1.1rem;
                    line-height: 1.4;
                    font-family: $poppins;

                    @media (max-width: $sm-max) {
                        font-size: 1.25rem;
                    }
                }
            }

            .selected {
                background-color: #742F4E;
                font-weight: lighter;
                color: #fff;
            }
        }

        .answers-multiple {

            margin-top: 5px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-around;
            max-width: 320px;

            .answer {
                height: 75px;
                width: 139px;
                background-color: #F2F2F2;
                // font-weight: bold;
                margin: 5px 0;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                p {
                    margin-top: 15px;
                    max-width: 138px;
                    font-size: 1.1rem;
                    line-height: 1.4;
                    font-family: $poppins;

                    @media (max-width: $sm-max) {
                        font-size: 1.25rem;
                    }
                }
            }

            .selected {
                background-color: #742F4E;
                font-weight: lighter;
                color: #fff;
            }
        }

        .info {
            font-size: 1.35rem;
            font-family: $overpass-light;
            font-style: italic;
            margin-top: 5px;
        }
    }
}

.suggested-programs-page {
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    @media (max-width: $sm-max) {
        margin: unset;
    }

    .title {
        font-size: 18px;
        font-family: $poppins;

        @media (max-width: $sm-max) {
            padding-right: 30px;
        }
    }

    .favorite-toggle-container {
        display: flex;
        flex-direction: row;
    }

    .subtitle {
        font-size: 18px;
        font-family: $poppins-medium;
    }

    .image-card {
        width: 243px;
        height: 253px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        margin-top: 5px !important;

        img {
            // height: 100%;
            width: auto;
            // margin-top: 55px;
            // margin-right: 35px;
            scale: 159%;
        }
    }

    .image-card-right {
        width: 243px;
        height: 253px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        margin-top: 5px !important;

        img {
            // height: 100%;
            width: auto;
            // margin-top: 55px;
            // margin-left: 35px;
            scale: 280%;
            margin-left: 110px;
        }
    }

    .suggested-like {
        position: absolute;
        top: 10px;
        right: 10px;
        float: unset;
    }

    .program-title {
        margin-top: 8px;
        font-size: 14px;
        font-family: $poppins-medium;
    }
}

.default-page-container {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: $sm-max) {
        width: 100%;
    }

    .default-opt-card {
        width: 320px;
        border-radius: 5px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        align-content: center;

        font-size: 14px;
        font-family: $poppins;

        h4 {
            margin-top: 7px;
        }
    }

    .subtitle {
        font-size: 15px;
        font-family: $poppins;
    }

    .image-card {
        width: 243px;
        height: 253px;
        overflow: hidden;
        display: flex;
        justify-content: center;

        img {
            // height: 100%;
            width: auto;
            // margin-top: 55px;
            // margin-right: 35px;
            scale: 159%;
        }
    }

    .image-card-big {
        width: 243px;
        height: 253px;
        overflow: hidden;
        display: flex;
        justify-content: center;

        img {
            // height: 100%;
            width: auto;
            // margin-left: 35px;
            scale: 200%;
        }
    }
}
