.bottom-button {
    width: 94%;
    position: absolute;
    height: 77px;
    bottom: 23px;
    background: var(--unnamed-color-000000) 0% 0% no-repeat padding-box;
    background: #000000 0% 0% no-repeat padding-box;
    border-radius: 3px;
    opacity: 1;
    border: none;

    &:hover,
    &:active {
        background: var(--unnamed-color-000000) 0% 0% no-repeat padding-box;
        background: #000000 0% 0% no-repeat padding-box;
        opacity: 0.9;
    }
}

.bottom-button-modal {
    width: 300px;
    height: 60px;
    background: var(--unnamed-color-000000) 0% 0% no-repeat padding-box;
    background: #222222 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    border: none;
    font-family: $poppins-light !important;

    span {
        font-size: 1.2rem !important;
    }

    &:hover,
    &:active {
        background: var(--unnamed-color-000000) 0% 0% no-repeat padding-box;
        background: #000000 0% 0% no-repeat padding-box;
        opacity: 0.9;
    }
}

.bottom-button-sm {
    width: 100%;
    margin-left: -11px;
    position: fixed;
    bottom: 0px;
    z-index: 9999;
    height: 77px;
    background: var(--unnamed-color-000000) 0% 0% no-repeat padding-box;
    background: #000000 0% 0% no-repeat padding-box;
    opacity: 1;
    border: none;
    border-radius: 0;

    span {
        font-size: 1.2rem !important;
    }
}

.bottom-button-n-fixed {
    width: 200px;
    height: auto;
    padding: 10px 20px;
    background: var(--unnamed-color-000000) 0% 0% no-repeat padding-box;
    background: #000000 0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 50px;
    margin: 0 auto;

    span {
        font-size: 1.2rem !important;
    }
}

.text-small-card {
    font-size: 30px !important;
}

.recover-div {
    .answer {
        height: 75px;
        width: 338px;
        background-color: #F2F2F2;
        // font-weight: bold;
        margin: 5px auto;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        p {
            margin-top: 15px;
            max-width: 230px;
            font-size: 1.2rem;
            line-height: 1.4;
            font-family: $poppins;

            @media (max-width: $sm-max) {
                font-size: 1.2rem;
            }
        }
    }

    .selected {
        background-color: #742F4E;
        font-weight: lighter;
        color: #fff;
    }

    .answer-small {
        height: 45px;
        width: 84px;
        background-color: #FFFFFF;
        // font-weight: bold;
        margin: 5px 0;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding-right: 10px;

        p {
            margin-top: 15px;
            font-size: 1.1rem;
            line-height: 1.4;
            font-family: $poppins;

            @media (max-width: $sm-max) {
                font-size: 1.25rem;
            }
        }
    }

    .selected-small {
        background-color: #742F4E;
        font-weight: lighter;

        p {
            color: #FFFFFF;
        }
    }
}

.confirmation-container {
    display: flex;
    align-items: center !important;

    p {
        line-height: 1.3 !important;
        margin-left: 10px;
        color: #000000;
    }
}

.disabled-bot-button {
    background: #d9d9d9 0% 0% no-repeat padding-box !important;
    opacity: 1;
    border: none !important;
    font-family: $poppins-medium !important;
    color: #aaaaaa !important;
}

.progress-indicator {
    width: 30px;
    padding: 3px 3px 0 3px;
    border-radius: 3px;
    background-color: #FFFFFF;
    font-family: $poppins-medium !important;

    @media (max-width: $sm-max) {
        width: 40px;
        height: 40px;
        padding: 3px 3px 0 3px;
        // font-size: 1.5rem !important;
    }
}

.indicator-container {
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.submit-button {
    background: #DE4A47 !important;
    opacity: 1;
    border: none !important;
    font-family: $poppins-medium !important;
}

.content-center {
    flex-direction: row;
    align-items: center !important;
    align-content: center !important;
}

.title-container {
    background-color: #FFF;
    border-radius: 46px;
    width: fit-content;
    padding: 0 10px 0 10px;
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
    font-family: $poppins !important;
}

.indicators-small {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 35px;

    .indicator {
        height: 4px;
        width: 40px;
        background-color: #DDD;
        border-radius: 4px;
        margin: 0px 2px 0px 2px
    }

    .active {
        background-color: #1a1a12;
    }
}

.video-pill {
    background-color: #FFF;
    padding: 0px 10px;
    border-radius: 50px;
    text-transform: capitalize;
}

.weekly-container {
    position: relative;

    .pill-selector-container {
        @media (max-width: $sm-max) {
            width: 200%;
            padding-right: 10px;
            padding-left: 20px;
        }
    }

    @media (max-width: $sm-max) {
        margin-bottom: 180px !important;
    }

    .bottom-button {
        width: 94%;
        position: absolute;
        bottom: -100px;
        height: 77px;
        background: #FFFFFF;
        color: #333 !important;
        border-radius: 5px;
        opacity: 1;
        border: none;

        &:hover,
        &:active {
            background: var(--unnamed-color-000000) 0% 0% no-repeat padding-box;
            background: #000000 0% 0% no-repeat padding-box;
            opacity: 1;

            span {
                color: #FFF;
            }
        }
    }
}

.text-bigger {
    font-size: 1.2rem !important;
}

.scroll-container-full {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    margin-bottom: 10px;
}

.scroll-container-full::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scroll-container-full {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.align-bottom {
    bottom: -20px !important;
}

.scroll-view {
    @media (max-width: $sm-max) {
        height: auto;
    }

    @media (max-width: $lg-max) {
        height: 100%;
        padding-bottom: 120px;
    }

    @media (max-width: $xl-max) {
        height: 100%;
        padding-bottom: 120px;
    }
}