@font-face {
    font-family: "Poppins";
    src: local("Poppins-Regular"),
        url("../../public/fonts/poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins-Medium";
    src: local("Poppins-Medium"),
        url("../../public/fonts/poppins/Poppins-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins-Light";
    src: local("Poppins-Light"),
        url("../../public/fonts/poppins/Poppins-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins-ExtraLight";
    src: local("Poppins-ExtraLight"),
        url("../../public/fonts/poppins/Poppins-ExtraLight.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins-Thin";
    src: local("Poppins-Thin"),
        url("../../public/fonts/poppins/Poppins-Thin.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins-Bold";
    src: local("Poppins-Bold"),
        url("../../public/fonts/poppins/Poppins-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins-SemiBold";
    src: local("Poppins-SemiBold"),
        url("../../public/fonts/poppins/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins-ExtraBold";
    src: local("Poppins-ExtraBold"),
        url("../../public/fonts/poppins/Poppins-ExtraBold.ttf") format("truetype");
}

@font-face {
    font-family: "Overpass-Light";
    src: local("Overpass-Light"),
        url("../../public/fonts/overpass/Overpass-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Overpass-Medium";
    src: local("Overpass-Medium"),
        url("../../public/fonts/overpass/Overpass-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Overpass";
    src: local("Overpass"),
        url("../../public/fonts/overpass/Overpass-Regular.ttf") format("truetype");
}